<template>
  <v-container>
    <span class="title-with-shadow-container">
      <span class="title-with-shadow">
        <span class="title-with-shadow-main">
          {{ text.calculator.title }}
        </span>
        <span class="title-with-shadow-shadow">
          {{ text.calculator.title }}
        </span>
      </span>
    </span>

    <div>
      <v-btn-toggle
        class="toggle-buttons"
        style="margin-top: 30px; margin-bottom: 30px;"
        mandatory>
        <v-btn :class="toggle_calc_type == 0 ? 'toggle-one-button active-toggle-green' :
                                               'toggle-one-button not-active-toggle-green'"
          style="border-style: solid; border-color: var(--v-green-base) !important; border-width: 1px 0px 1px 1px;"
          @click="on_change(0)">
          {{ text.calculator.simple }}
        </v-btn>
        <v-btn :class="toggle_calc_type == 1 ? 'toggle-one-button active-toggle-green' :
                                               'toggle-one-button not-active-toggle-green'"
          style="border-style: solid; border-color: var(--v-green-base) !important; border-width: 1px 1px 1px 0px;"
          @click="on_change(1)">
          {{ text.calculator.clever }}
        </v-btn>
      </v-btn-toggle>
    </div>
    <span v-if="toggle_calc_type == 1">
      <CleverCalculator :set_is_products_empty="set_is_products_empty"/>
    </span>
    <span v-else>
      <SimpleCalculator :set_is_products_empty="set_is_products_empty"/>
    </span>
    <ChangeCalculatorDialog v-if="change_dialog" :dialog="change_dialog" :toggleFunc="toggle_type" :closeDialog="() => { change_dialog=false }"/>
    </v-container>
</template>

<script>
import all_text from './ru-text'
import SimpleCalculator from './SimpleCalculator.vue'
import { COMPONENT_NAME, ALERT_TYPE } from './define.js'
import CleverCalculator from './CleverCalculator.vue'
import ChangeCalculatorDialog from './ChangeCalculatorDialog.vue'
 export default {
  name: 'Calculator',
  components: {
    SimpleCalculator,
    CleverCalculator,
    ChangeCalculatorDialog
  },
  data() {
    return {
      text: all_text,
      toggle_calc_type: 1,
      is_products_empty: true,
      change_dialog: false
    }
  },
  computed: {
    existingProductInfo() {
      // return this.$store.getters.PRODUCTS;
      return [];
    },
  },
  created() {
    this.$store.commit("SET_LOADING", true)
    if (this.$store.getters.PRODUCTS == null) {
      this.$store.dispatch('GET_PRODUCTS').then((res) => {
        this.$store.commit("SET_LOADING", false || this.$store.getters.RECIPES == null)
        if (res.status != 200) {
          this.$store.commit("RAISE_ALERT", 
                                {'message': this.text.common_error_message,
                                'status': ALERT_TYPE.ERROR})
        }
      }).catch((err) => {
          this.$store.commit("RAISE_ALERT", 
                                {'message': this.text.common_error_message,
                                'status': ALERT_TYPE.ERROR})
        });
      }
      if (this.$store.getters.RECIPES == null) {
        this.$store.dispatch('GET_RECIPES').then((res) => {
          this.$store.commit("SET_LOADING", false || this.$store.getters.RECIPES == null)
          if (res.status != 200) {
            this.$store.commit("RAISE_ALERT", 
                                {'message': 'Что-то пошло не так, проверьте все и повторите попытку. \
                                              Если она будет неудачной снова, то напишите в поддержку.',
                                'status': ALERT_TYPE.ERROR})
          }
        }).catch((err) => {
          // console.log('RECIPES', err.response)
          this.$store.commit("RAISE_ALERT", 
                                {'message': 'Что-то пошло не так, проверьте все и повторите попытку. \
                                              Если она будет неудачной снова, то напишите в поддержку.',
                                'status': ALERT_TYPE.ERROR})
        });
      }
      this.$store.commit("SET_LOADING", this.$store.getters.PRODUCTS == null || this.$store.getters.PRODUCTS == null)
    },
    methods: {
      toggle_type() {
        this.is_products_empty = true
        this.toggle_calc_type = this.toggle_calc_type == 1 ? 0 : 1
      },
      on_change(type_idx) {
        if (this.toggle_calc_type == type_idx) {
          return
        }
        if (this.is_products_empty) {
          this.toggle_type()
        } else {
          this.change_dialog = true
        }
      },
      set_is_products_empty(is_empty) {
        this.is_products_empty = is_empty
      }
    }
  }
</script>
<style>
@import './common.scss';
.active-toggle-green {
  color: var(--v-main_background-base) !important;
  background-color: var(--v-green-base) !important;
  opacity: 1 !important;
}

.not-active-toggle-green {
  color: var(--v-green-base) !important;
  background-color: var(--v-main_background-base) !important;
  opacity: 1 !important;
}

</style>
