<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      :fullscreen="$vuetify.breakpoint.width < mobile_border"
      scrollable
      max-width="70%">
      <v-card color="main_background">
        <v-card-text>
          <v-form ref="new_ingredient" v-model="valid" validate-on="submit input">
            <div v-if="!changeMode" class="new-ingredient-toggle-container">
              <v-btn-toggle v-model="draft_toggleVariant" mandatory class="toggle-buttons">
                <v-btn :class="draft_toggleVariant == 0 ? 'toggle-one-button active-toggle-orange' :
                                                          'toggle-one-button not-active-toggle-orange'"
                  style="border-style: solid; border-color: var(--v-orange-base) !important; border-width: 1px 0px 1px 1px;"
                  small>
                  {{ text.recipe.prducts_from_db }}
                </v-btn>
                <v-btn :class="draft_toggleVariant == 1 ? 'toggle-one-button active-toggle-orange' :
                                                            'toggle-one-button not-active-toggle-orange'"
                    style="border-style: solid; border-color: var(--v-orange-base) !important; border-width: 1px 1px 1px 0px;"
                    small>
                    {{ text.recipe.new_product }}
                </v-btn>
              </v-btn-toggle>
            </div>
            <div v-if="editCustomIngredient() || addNewIngredient()"
                class="two-element-in-row"
                :style="changeMode ? 'padding-top: 30px' : ''">
              <v-select 
                  class="pr-3 pl-3"
                  :items="categories" 
                  v-model="draft_productItem[draft_toggleVariant]['product_type']" 
                  :label="text.category + ' *'"
                  :rules="[rules.required]"
              ></v-select>
            </div>
            <div class="two-element-in-row" v-if="editCustomIngredient() || addNewIngredient()">
              <v-select 
                  v-if="draft_toggleVariant == 0"
                  class="mt-0 pr-3 pl-3"
                  :rules="[rules.required]"
                  :items="products"
                  v-model="draft_productItem[draft_toggleVariant]['name']"
                  :label="text.recipe.product + ' *'">
              </v-select>
              <v-text-field
                  v-else
                  class="mt-0 pr-3 pl-3"
                  :rules="[rules.required, rules.string, custom_rules.productNameUnique]"
                  v-model="draft_productItem[draft_toggleVariant]['name']"
                  :label="text.recipe.product + ' *'">
              </v-text-field>
            </div>
            <div class="three-element-in-row" v-if="editCustomIngredient() || addNewIngredient()" >
              <span
                v-for="(name, label) in componentsName"
                :key="name + '_new_product'"
                class="pt-0 pb-0 pr-3 pl-3">
                <v-text-field
                    v-if="productFromDB()"
                    :label="name"
                    readonly
                    :disabled="draft_productItem[draft_toggleVariant][label] == undefined"
                    v-model="draft_productItem[draft_toggleVariant][label]"
                    suffix="%">
                </v-text-field>
                <v-text-field
                    v-else
                    :label="name + ' *'"
                    :rules="[rules.required, rules.double]"
                    v-model="draft_productItem[draft_toggleVariant][label]"
                    @click="resetErrorMsg()"
                    suffix="%">
                </v-text-field>
              </span>
            </div>
            <div class="two-element-in-row" v-if="!changeMode">
              <v-text-field
                class="pr-3 pl-3"
                :label="text.gramms  + ' *'"
                :rules="[rules.required, rules.double]"
                v-model="draft_productItem[draft_toggleVariant]['amount']" 
                :suffix="text.suffix_gramm">
              </v-text-field>
            </div>
          </v-form>
        </v-card-text>

        <div :class="[err_msg_class, 'err-msg']">
          {{ err_msg }}
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <button
            :disabled="!valid"
            :style="valid ? '' : 'opacity: 60%'"
            class="common-button new-ingredients-button"
            @click="checkAndCloseDialog()">
            {{ text.save }}
          </button>
          <button
            class="common-button new-ingredients-button"
            @click="closeDialog({}, false)">
            {{ text.cancel }}
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { COMPONENT_NAME, ALERT_TYPE, MOBILE_BORDER } from './define.js'
import all_text from './ru-text'
import all_rules from './rules'
export default {
  props: {
    productItem: {
      Object
    },
    dialog: {
      type: Boolean,
    },
    closeDialog: {
      type: Function
    },
    changeMode: {
      type: Boolean
    }
  },
  name: 'NewIngredient',
  data() {
    return {
      componentsName: COMPONENT_NAME,
      valid: false ,

      draft_toggleVariant: 0,
      draft_productItem: [],

      products: [],
      err_msg: all_text.product.wrong_component_sum,
      err_msg_class: 'err-msg-hide',

      existingProductInfo: [],
      categories: [],

      mobile_border: MOBILE_BORDER,

      text: all_text,
      rules: all_rules,
      custom_rules: {
          productNameUnique: value => {
              if (!value) {
                return all_text.need_value
              }
              if (this.editCustomIngredient()) {
                return true;
              }
              let products = this.$store.getters.PRODUCTS != null ? this.$store.getters.PRODUCTS : {};
              let temp = value.trim()
              let check = Object.keys(products).every(productType => {
                  return products[productType].every(info => {
                  return info.name != temp
                  })
              })
              return !!check || all_text.not_unique_name
          }
      },
    }
  },
  created() {
    this.draft_toggleVariant = this.productItem.toggleVariant || 0

    let newProductItem = [{'product_type': undefined}, {}]
    let toggleVar = this.draft_toggleVariant
    Object.entries(this.productItem).forEach(([key, info]) => {
      newProductItem[toggleVar][key] = info
      // tg = 0 -> 1 - 0 = 1, tg = 1 -> 1 - 1 = 0
      newProductItem[1 - toggleVar][key] = undefined
    })
    newProductItem[1 - toggleVar]['toggleVariant'] = 1 - toggleVar
    this.draft_productItem = newProductItem;

    this.existingProductInfo = this.$store.getters.PRODUCTS;

    if (this.$store.getters.PRODUCT_TYPES == null) {
      this.$store.dispatch('GET_PRODUCT_TYPES').then((res) => {
        if (res.status == 200) {
          this.categories = res.data
        } else {
          this.closeDialog({}, false)
          this.$store.commit("RAISE_ALERT", 
                              {'message': this.text.common_error_message,
                                'status': ALERT_TYPE.ERROR})
        }
      }).catch(() => {
        this.closeDialog({}, false)
        this.$store.commit("RAISE_ALERT", 
                            {'message': this.text.common_error_message,
                            'status': ALERT_TYPE.ERROR})
      });
    } else {
      this.categories = this.$store.getters.PRODUCT_TYPES
    }

    Object.entries(this.existingProductInfo).forEach(([product_type, ]) => {
      Object.entries(this.existingProductInfo[product_type]).forEach(([, info]) => {
        this.products.push(info.name);
      })
    })
  },
  watch: {
    'draft_productItem.0.product_type': function(newVal, oldVal) {
      let newProducts = []
      Object.entries(this.existingProductInfo[newVal]).forEach(([, info]) => {
        newProducts.push(info.name);
      })
      this.products = newProducts;
      if (oldVal != undefined) {
        this.draft_productItem[0]['name'] = undefined
      }
    },
    'draft_productItem.0.name': function(newVal) {
      if (newVal == undefined) {
        Object.entries(this.componentsName).forEach(([component, ]) => {
            this.draft_productItem[0][component] = undefined
        });
      } else {
        // 0 - choose from existing products
        let temp_product = undefined;
        if (this.draft_productItem[0]['product_type'] == undefined) {
          let real_product_type = undefined
          Object.entries(this.existingProductInfo).every(([product_type, products_info]) => {
            temp_product = products_info.find((info) => newVal == info.name)
            if (temp_product) { 
              real_product_type = product_type;
              return false;
            }
            return true;
          })
          this.draft_productItem[0]['product_type'] = real_product_type
        } else {
          let product_type = this.draft_productItem[0]['product_type'];
          temp_product = this.existingProductInfo[product_type].find((info) =>
                                                                         newVal == info.name)
        }
        Object.entries(temp_product).forEach(([component, ]) => {
            this.draft_productItem[0][component] = component in COMPONENT_NAME ? 
                                                    temp_product[component].toFixed(2) :
                                                    temp_product[component]
        })
      }
    }
  },
  methods: {
    productFromDB() {
      return this.draft_toggleVariant == 0
    },
    editCustomIngredient() {
      return this.changeMode && this.draft_toggleVariant != 0
    },
    addNewIngredient() {
      return !this.changeMode
    },
    checkAndCloseDialog() {
      if (this.draft_toggleVariant == 1 && !this.checkComponentSumValid()) {
        this.valid = false;
        this.err_msg_class = 'err-msg-show'
      } else {
        this.closeDialog(this.draft_productItem[this.draft_toggleVariant], true)
      }
    },
    checkComponentSumValid() {
      let sum = 0
      Object.entries(this.componentsName).forEach(([name, ]) => {
        if (this.draft_productItem[1][name] == undefined) {
            sum += 0
        } else {
            sum += parseFloat(this.draft_productItem[1][name])
        }
      })
      return Math.abs(100 - sum) <= 2
    },
    resetErrorMsg() {
      if (this.err_msg_class != 'err-msg-hide'){
        this.err_msg_class = "err-msg-hide"
        this.valid = true
      }
    },
  },
}
</script>
<style>
@import './common.scss';

.new-ingredient-toggle-container {
  padding-top: 20px;
  justify-content: center;
  display: flex;
  padding-bottom: 20px
}

.new-ingredients-button {
  padding: 2px 10px;
  margin-left: 10px;
}

</style>
