<template>
  <v-container>
    <span class="title-with-shadow-container">
      <span class="title-with-shadow">
        <span class="title-with-shadow-main">
          {{ text.products }}
        </span>
        <span class="title-with-shadow-shadow">
          {{ text.products }}
        </span>
      </span>
      <span class="change-icons-container">
        <v-btn
          v-if="productInfo.is_owner"
          @click="onUpdateProduct()"
          fab
          icon
          :disable='$store.getters.LOADING'>
          <img src="./icons/product_edit.svg"
            :title="text.change"
            height="30px"
            width="30px" />
        </v-btn>
        <v-btn
            v-if="productInfo.is_owner"
            @click="deleteProductDialog=true"
            fab
            icon
            :disable='$store.getters.LOADING'>
          <img src="./icons/product_remove.svg"
              :title="text.remove"
              height="30px"
              width="30px" />
        </v-btn>
      </span>
    </span>

    <ProductTitle :title="productInfo.name" class="pr-title-margin" />

    <span class="type-title-container">
      <img class="pattern-left" src="./icons/pattern_left.svg" />
      <span style="grid-column-start: 2;
                   justify-self: center;
                   align-self: center;">
        <span class="circle circle-size"></span>
        <span class="type-name type-color">
          {{ productInfo.product_type }}
        </span>
        <span class="circle circle-size"></span>
      </span>
    </span>

    <hr class="line" />

    <CreateUpdateProduct v-if="openUpdateProductDialog == true"
          :closeDialog="onCloseUpdateProductDialog" 
          :productItem="productInfo" 
          :dialog="openUpdateProductDialog"
          :changeMode="true"/>

    <span class="content-container">
      <span class="table-container">
        <table style="border: none;">
          <tbody>
            <tr v-for="component in componentTableContent"
              style="height: 50px" :key="component.name">
              <td>
                <span style="margin-left: 40px">
                  {{ component.name }}
                </span>
              </td>
              <td style="text-align: center;">
                <span>
                  {{ component.info ? component.info.toFixed(2) : 0 }} %
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <table style="border: none; margin-top: 60px" v-if="kkallTableContent !== null">
          <tbody>
            <tr v-for="component in kkallTableContent"
              style="height: 50px" :key="component.name">
              <td>
                <span style="margin-left: 40px">
                  {{ component.name }}
                </span>
              </td>
              <td style="text-align: center;">
                <span>
                  {{ component.info ? component.info.toFixed(0) : 0 }} ккал
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      
        <span style="display: flex; margin-top: 30px; margin-bottom: 30px;" >
          <v-form ref="valid_price_ref" v-model="valid_price" validate-on="submit input">
            <span class="price-conteiner">
              <span class="price" style="font-weight: bold;">
                {{ text.product.price }}:
              </span>
            
              <v-text-field 
                id="price-input-1"
                class="price-input"
                style="margin-right: 10px"
                :suffix="text.product.rub"
                :rules="[rules.required, rules.double]"
                :disabled="!priceChange"
                v-model="price">
              </v-text-field>

              <v-text-field
                class="price-input"
                :suffix="text.gramm"
                :rules="[rules.required, rules.double]"
                v-model="weight"
                :disabled="!priceChange">
              </v-text-field>
            </span>
          </v-form>
          <button
            :disabled="!valid_price"
            v-if="priceChange == true"
            :class="valid_price ? 'change-price change-price-btn-size' : 'change-price change-price-btn-size common-disable-text-color'"
            @click="closePriceDialog()">
            <img
                src="./icons/okay.svg"
                height="30px"
                width="30px"/>
          </button>
          <button
            v-else  
            class="change-price change-price-btn-size"
            @click="onClickCangePrice()">
            <img
                src="./icons/product_edit.svg"
                height="30px"
                width="30px"/>
          </button>
        </span>
        <span class="price-conteiner" style="border-radius: 10px" v-if="price">
          <span class="price" style="font-weight: bold;">
            {{ text.product.price }}:
          </span>
          <span class="price" style="width: -webkit-fill-available; text-align: center">
            {{ normalized_price }} {{ text.product.rub }}
          </span>
          <span class="price" style="width: -webkit-fill-available; text-align: center">
            {{ normalized_price_weight }} {{ text.gramm }}
          </span>
          <span style="width: 260px"></span>
        </span>
      </span>
      <span class="stop-product-container">
        <StopProducts :info="productInfo" />
      </span>
    </span>
    
    <DeleteProductDialog v-if="deleteProductDialog && productInfo.is_owner"
                        :closeDialog="closeDeleteRecipesDialog"
                        :recipes="recipeForDelete"
                        :dialog="deleteProductDialog"/>

  </v-container>
</template>

<script>
import CreateUpdateProduct from './CreateUpdateProduct.vue'
import StopProducts from './StopProducts.vue'
import DeleteProductDialog from './DeleteProductDialog.vue'
import ProductTitle from './ProductTitle.vue'
import { COMPONENT_NAME, KALLORIES, ALERT_TYPE, MOBILE_BORDER } from './define.js'
import all_text from './ru-text'
import all_rules from './rules'
export default {
  name: 'Product',
  components: {
    CreateUpdateProduct,
    StopProducts,
    DeleteProductDialog,
    ProductTitle
  },
  data() {
    return {
      valid_price: false, 
      weight: undefined,
      price: undefined,
      priceChange: false,
      normalized_price: undefined,
      normalized_price_weight: 1000,

      openUpdateProductDialog: false,

      productInfo: {},
      recipeForDelete: [],
      deleteProductDialog: false,

      text: all_text,
      rules: all_rules,
      mobile: MOBILE_BORDER
    }
  },
  created() {
    if (this.$store.getters.PRODUCTS == null) {
      this.$store.dispatch('GET_PRODUCT', {'id': this.$route.params.id}).then((res) => {
        if (res.status == 200) {
            this.productInfo = res.data
        } else {
            this.$store.commit("RAISE_ALERT", 
                                {'message': this.text.common_error_message,
                                'status': ALERT_TYPE.ERROR})
        }
        this.setupPriceInfo()
      }).catch(() => {
          this.$store.commit("RAISE_ALERT", 
                              {'message': this.text.common_error_message,
                              'status': ALERT_TYPE.ERROR})
      });
    } else {
      let allProducts = this.$store.getters.PRODUCTS
      let product_type = this.$route.params.product_type
      let id = this.$route.params.id

      this.productInfo = {}
      if (product_type in allProducts) {
          allProducts[product_type].forEach((element, ) => {
            if (element.id == id) {
              this.productInfo = element
            }
          });
      }

      if (this.productInfo == {}) {
          this.$store.commit("RAISE_ALERT", 
                              {'message': this.text.common_error_message,
                               'status': ALERT_TYPE.ERROR})
      }
      this.setupPriceInfo()
    }
  },
  computed: {
    componentTableContent() {
      let content = []
      if (this.productInfo) {
        Object.entries(COMPONENT_NAME).forEach(([component, name]) => {
          content.push({'name': name, 'info': this.productInfo[component]})
        })
      }
      return content
    },
    kkallTableContent() {
      let content = []
      let info_exists = false
      if (this.productInfo) {
        Object.entries(KALLORIES).forEach(([component, name]) => {
          info_exists |= this.productInfo[component] > 0
          content.push({'name': name, 'info': this.productInfo[component]})
        })
      }

      return info_exists ? content : null
    }
  },
  methods: {
    setupPriceInfo() {
      if (this.productInfo.price > -1 && this.productInfo.price_weight > -1) {
          this.price = this.productInfo.price
          this.weight = this.productInfo.price_weight
          this.normalized_price = (this.price * this.normalized_price_weight /
                                   this.productInfo.price_weight).toFixed(2)
      }
    },
    closeDeleteRecipesDialog(save, result, message=null) {
      if (save) {
        if (result) {
          this.deleteProduct()
        } else {
          this.$store.commit("RAISE_ALERT", 
                                {'message': message ? message : this.text.common_error_message,
                                  'status': ALERT_TYPE.ERROR})
        }
      }
      this.deleteProductDialog = false
    },
    deleteProduct() {
        this.$store.dispatch('REMOVE_PRODUCT', this.productInfo).then((res) => {
        if (res.status == 200) {
            this.$store.commit("RAISE_ALERT", 
                                {'message': this.text.successful_remove,
                                'status': ALERT_TYPE.SUCCESS})
            this.$router.push('/products')
        } else {
            this.$store.commit("RAISE_ALERT",
                                {'message': this.text.common_error_message,
                                'status': ALERT_TYPE.ERROR})
        }
        }).catch((err) => {
            if (err.response.status == 300) {
              this.recipeForDelete = err.response.data.recipes
              this.deleteProductDialog = true
            } else {
                this.$store.commit("RAISE_ALERT",
                                    {'message': this.text.common_error_message,
                                    'status': ALERT_TYPE.ERROR})
            }
        });
    },
    closePriceDialog() {
      this.$store.dispatch('UPDATE_PRICE', {'product_id': this.productInfo.id,
                                            'price': this.price,
                                            'weight': this.weight}).then((res) => {
          if (res.status == 200) {
            this.productInfo.price = this.price
            this.productInfo.price_weight = this.weight
          } else {
            this.$store.commit("RAISE_ALERT",
                                {'message': this.text.common_error_message,
                                  'status': ALERT_TYPE.ERROR})
          }
          this.priceChange = false
          this.setupPriceInfo()
        }).catch(() => {
          this.priceChange = false
          this.setupPriceInfo()
          this.$store.commit("RAISE_ALERT",
                            {'message': this.text.common_error_message,
                              'status': ALERT_TYPE.ERROR})
        });
    },
    onUpdateProduct() {
      this.openUpdateProductDialog = true;
    },
    onCloseUpdateProductDialog(isSave, isSuccsess, newProduct) {
        if (isSave) {
          if (isSuccsess) {
            Object.keys(newProduct).forEach((key, ) => {
              this.productInfo[key] = newProduct[key]
            })
          }
        }
        this.openUpdateProductDialog = false;
    },
    onClickCangePrice() {
      this.priceChange = true; 
      // var mousePosition = $('price-input-1')[0].selectionStart;
      let elem1 = document.querySelector(".price-conteiner .v-text-field__slot input");
      // console.log(elem1)
      console.log(elem1.selectionStart)
      const length = elem1.value.length
      elem1.setSelectionRange(length, length)

      // let elem = document.getElementById('price-input-1');
      // const length = elem.value.length;

      //       // console.log(elem.selectionStart)
      // // console.log(length)
      // elem.focus();
      // elem.setSelectionRange(length, length + 1);
      }
  }
}

</script>

<style lang="scss">

.line {
  position: absolute;
  width: 100%;
  left: 50%;
  margin-left: -50%;
  height: 2px; /* or whatever. */
  background-color: var(--v-green-base);
}

table {
  border-collapse: collapse;
  width: 100%;
  border: none;
}

th, td {
  border-bottom: 3px solid var(--v-green-base);
}

tr:hover {
  background-color: var(--v-main_background2-base);
}

.change-icons-container {
  text-align: end;
  align-self: center;
}

.type-title-container {
  display: grid;
  grid-template-columns: 25% 50% 25%;
}

.name-container {
  grid-column-start: 2;
  justify-self: center;
  align-self: center;
}

.product-name {
  color: var(--v-green-base);
  position: relative;
  z-index: 2;
}

.product-name-font-size {
  font-size: 36px;
}

.name-icon {
  position: absolute;
  z-index: 1;
  opacity: 0.7;
}

.name-icon-align {
  height: 98px;
  width: 98px;
  margin-left: -35px;
  margin-top: -20px;
}

.img-right {
  content: url("./icons/pattern_right.svg");
}

.type-name {
  font-size: 24px;
}

.type-color {
  color: var(--v-orange-base);
}

.circle {
  background-color: var(--v-orange-base);
  border-radius: 50%;
  display: inline-block;
}

.circle-size {
  height: 10px;
  width: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.pattern-left {
  position: relative;
  grid-column-start: 1;
  right: 35%;
}

.content-container {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 40px;
}

.stop-product-container {}

.table-container {
  margin-right: 40px;
  margin-top: 20px;
}

.change-price-btn-size {
  width: 120px;
  height: 70px;
  font-size: 16px;
}

.pr-title-margin {}

@media (max-width: 950px) {
  .pr-title-margin {
    margin-top: 40px;
  }

  .type-title-container {
    height: 88px;
  }

  .change-price-btn-size {
      width: 100px;
      height: 70px;
      font-size: 12px;
  }

  .change-icons-container {
    grid-row: 1;
    text-align: end;
    align-self: center;
  }

  .product-name-font-size {
    font-size: 20px;
  }

  .name-icon-align {
    height: 74px;
    width: 74px;
    margin-left: -29px;
    margin-top: -20px;
  }

  .img-right {
    content: url("./icons/pattern-right-small.svg");
    margin-left: -80px;
  }

  .type-name {
    font-size: 14px;
  }

  .circle-size {
    height: 5px;
    width: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .pattern-left {
    position: relative;
    grid-column-start: 1;
    right: 60%;
  }

  .content-container {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 40px;
  }

  .stop-product-container {
    grid-row: 1;
  }

  .table-container {
    margin: auto;
    margin-top: 20px;
  }

}

.price-conteiner {
  width: 100%;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  height: 70px;
  background-color: var(--v-green-base);
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.change-price {
  background-color: var(--v-main_background-base) !important;
  border-radius: 0px 10px 10px 0px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);;
}

.price {
  width: 40px;
  margin-right: 10px;
  align-self: center;
  font-size: 15px;
  color: var(--v-main_background-base);
}

.price-input>.v-text-field .v-input__control .v-input__slot:before {
  color: var(--v-main_background-base) !important;
  border-color: var(--v-main_background-base) !important;
}

.price-conteiner>.v-text-field>.v-input__control>.v-input__slot:after, .price-conteiner>.v-text-field>.v-input__control>.v-input__slot:before {
  border-color: var(--v-main_background-base) !important;
}

.price-conteiner>.theme--light.v-text-field>.v-input__control>.v-input__slot:before {
  border-color: var(--v-main_background-base) !important;
}

.price-conteiner>.theme--light.v-text-field>.v-input__control>.v-input {
  caret-color: var(--v-main_background-base) !important;
}

.price-conteiner>.theme--light.v-input, .price-conteiner>.theme--light.v-input input, .price-conteiner>.theme--light.v-input textarea {
  color: var(--v-main_background-base) !important;
  text-align: center;
  caret-color: var(--v-main_background-base) !important;
}

.price-conteiner>.theme--light.v-text-field.v-input--is-disabled .v-input__slot:before {
  border-image-source: repeating-linear-gradient(90deg, rgba(233, 228, 222, 0.7) 0px, rgba(233, 228, 222, 0.7) 2px, transparent 0px, transparent 4px);
}

</style>
