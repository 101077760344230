const main_name = 'Ganache Calculator'

const common_error_message = 'Что-то пошло не так, пожалуйста, повторите попытку. \n\
Если проблема появиться снова, пожалуйста, напишите в поддержку.'

const no_connection_error_message = 'Что-то пошло не так, проверьте подключение к интренету и повторите попытку. \n\
Если проблема появиться снова, пожалуйста, напишите в поддержку.'

const product_save_succesfully = 'Продукт успешно сохраннен'

const product_update_succesfully = 'Продукт успешно изменен'

const succsesfull_change = 'Изменение прошло успешно'

const email_confirmation = 'На почту прийдет письмо, \nперейдите по ссылке, чтобы подтвердить изменение'

const new_m = 'Новый '

const new_s = 'Новое '

const new_w = 'Новая '

const cancel = 'Отмена'

const save =  'Сохранить'

const remove = 'Удалить'

const change = 'Изменить'

const calculate = 'Расчитать'

const search = 'Поиск'

const total = 'Всего'

const categories = 'Категории'

const category = 'Категория'

const stop_products = 'Особенные продукты'

const others = 'еще'

const next = 'Дальше'

const return_back = 'назад'

const back = 'Назад'

const need_value = 'Необходимо ввести значение'

const not_unique_name = 'Такое название уже существует'

const show = 'Просмотреть'

const reset = 'Сбросить'

const recipes_not_found = 'Рецептов не найдено'

const no_data = 'Нет данных'

const gramm = 'грамм'

const gramms = 'Граммы'

const suffix_gramm = 'г'

const one_product = 'Продукт '

const products = 'Продукты '

const not_saved_one = ' не сохранен '

const not_saved_several = ' не сохранены '

const saved_it_before_recipe = 'сохраните его перед сохранением рецепта'

const saved_them_before_recipe = 'сохраните их перед сохранением рецепта'

const recipe_is_not_exists = 'Такого рецепта не существует'

const settings = "Настройки"

const product_created = 'Продукт успешно создан'

const changes_saved = 'Изменеия сохранены'

const user = {
    remove_accaunt: 'Вы дейсвительно хотите удалить аккаунт ?',
    accaunt_succesfully_deleted: 'Аккаунт успешно удален',
    accaunt_wrong_deleted: 'Что-то пошло не так, проверьте пароль и повторите попытку. \
Если она будет неудачной снова, то напишите в поддержку.'
}

const recipe = {
    by_final_weight: 'По итоговому весу',
    by_portion_amount: 'На количество порций',
    by_final_weight_short: 'По весу',
    by_portion_amount_short: 'На количество',
    final_weight: 'Итоговый вес',
    one_portion_weight: 'Вес одной порции',
    final_portion_amount: 'Итоговое количество порций',
    calculate_on_amount: 'Рассчитать на количество',
    portion_amount: 'Количество порций',
    portion_weight: 'Вес порций',
    save_as_pdf: 'Сохранить в pdf файл',
    product: 'Продукт',
    amount_gramm_in_candy: 'Кол-во грамм в конфетах',
    new_recipe: 'Новый рецепeт',
    calculate_price: 'Рассчитать стоимость',
    price: 'Цена',
    weight: 'Вес',
    price_table_comment: 'Цена будет расчитана с учетом текущех весов товара. \n\
Если построена таблица на количество, то веса будут ориантироваться на эту таблицу.',
    proportion: 'Пропорции',
    components: 'Компоненты',
    ingredients: 'Ингредиенты',
    remove_succesful: 'Рецепт успешно удален',
    save_succesful_to_db: 'Рецепт успешно сохранен в базу',
    no_product: 'Нет продуктов',
    add_product: 'Добавить ингредиент',
    recipe_name: 'Название рецепта',
    recipe_not_found: 'Информация по рецепту не найдена',
    remove_confirmation: 'Вы уверены, что хотите удалить этот рецепт из базы данных?',
    save_to_db: "Сохранить рецепт в базу",
    save_changes: "Сохранить изменения",
    setup_name: 'Укажите название рецепта',
    put_name: 'введите название',
    change_not_unique_name: 'Название рецепта не уникальное, пожалуйста, измените название',
    change_name: 'введите название',
    remove_recipe_error: 'Возникли проблемы с удалением рецептов:',
    only_owner_error: 'вы не являетесь создателем этого рецепта',
    try_to_remove: 'Попробуйте удалить рецепты, созданные вами, с их страницы',
    write_to_support: 'Если не выйдет, пожалуйста, напишите в поддержку. ',
    recipes: 'Рецепты',
    recipe: 'Рецепт',
    create_new: 'Создать рецепт',
    only_my: 'Показывать только мои рецепты',
    prducts_from_db: "Продкты из базы",
    new_product: "Новый продукт",
    change_product: "Изменить продукт",
    remove_product: "Удалить продукт",
    save_product_to_db: "Сохранить продукт в базу",
    recipe_saved: 'Рецепт успешно сохраннен',
}

const components = {
    name: 'Название',
    ratio: 'Процентное содержание (%)',
    solid_fats: 'Твердые жиры (%)',
    sugar: 'Сахар (%)',
    soft_fats: 'Мягкие жиры (%)',
    water: 'Воды (%)',
    alcohol: 'Алкоголь (%)',
    d_s: 'Твердое вещество (%)',
    amount: 'Колличество (г)',
}

const product = {
    create_new: 'Создать продукт',
    only_my: 'Показывать только мои продукты',
    name: 'Название',
    price: 'Цена',
    change_price: 'Изменить цену',
    rub: 'руб.',
    not_set: 'не установлено',
    weight_in_gramm: 'Введите вес в граммах',
    price_on_weight: 'Введите цену на вес в рублях',
    wrong_price_data: 'Ввес или цена введены не корректно, попробуйте еще раз',
    not_found: 'Продуктов не найдено',
    remove_confirmation: 'Вы уверены, что хотите удалить этот продукт из базы ?',
    product_in_recipes: 'Продукт содержиться в следующих рецептах:',
    recmove_recipes: 'Удалить рецепты',
    successful_remove: 'Продукт успешно удален',
    successful_change_price: 'Цена успешно изменена',
    wrong_component_sum: 'Сумма компонентов отличатеся от 100 более чем на 2 пункта',
    price_suffix: 'р. на 100 грамм'
}

const login = {
    login_email_label: 'Введите логин или email',
    password_label: 'Введите пароль',
    email_label: 'Введите email',
    reset_password_form: 'Форма сброса пароля',
    sent_email: 'Послать письмо',
    cancel: 'Отмена',
    login_form_name: 'Вход в ' + main_name,
    enter: 'Вход',
    foget_password: 'Забыли пароль ?',
    wrong_login_or_password: 'Не верный логин или пароль.',
    wrong_password: 'Не верный пароль.\n',
    link_email_sent: 'Письмо с ссылкой отправлено.',
    wrong_email: 'Этот email не ассоциирован ни с одним пользователем.',
    singup_form: 'Регистрация в ' + main_name,
    firstName_label: 'Введите имя',
    secondName_label: 'Введите фамилию',
    login_label: 'Веведите login',
    secondPassword_label: 'Повторите пароль',
    singup: 'Зарегестрироваться',
    successful_singup: "Регистрация прошла успешно.\n Вам придет на почту письмо с ссылкой для активации",
    not_unique_username_err: 'Пользователь с таким именем уже существует.\n',
    wrong_name: 'Не корректное имя.',
    not_unique_email_err: 'Пользователь с таким email уже существует.\n',
    password_like_ermail_username: 'Пароль похож на username или email, пожалуйста измените его.\n',
    successful_activation: 'Активация аккаунта прошла успешно',
    successful_confirmation_email: 'Изменение email успешно завершено',
    somthing_went_wrong: 'Что-то пошло не так.',
    somthing_went_wrong_help: 'Пожалйста, убедитесь, что этот email не был раньше использован для регистрации\n\
Если остались вопросы, напишите нам в поддержку',
    unsuccessful_activation: 'Что-то пошло не так.',
    unsuccessful_activation_help: "1. Убедитесь, что скопировали ссылку верно \n\
2. Проверьте, возможно аккаунт уже активировался. \n\
3. Повторите попытку \n\
4. Если остались проблемы с активацией аккаунта, пожалуйста, \n\
   пишите нам на почту sofya@gmial.com",
   change_form: 'Форма измнения',
   current_password_label: 'Введите текущий пароль',
   new_password_label: 'Введите новый пароль',
   re_new_password_label: 'Введите новый пароль',
   name: 'Имя',
   sure_name: 'Фамилия',
   user_name: 'UserName',
   email: 'Электронная почта',
   change_password: 'Изменить пароль',
   created_products: 'Создано продуктов',
   created_recipes: 'Создано рецептов',
   user_tarif: 'Текущий тариф',
   plane_finish_after: 'До конца периода осатлось',
   days: ' дней',
   day: ' день',
   logout: 'Выйти',
   remove_acc: 'Удалить аккаунт',
   user_data: 'Личные данные',
   user_activity: 'Активность и тариф',
   manage_acc: 'Управление аккаунтом',
   password_changed: 'Пароль успешно изменен',
   password_change_fail: 'Ошибка. Не удалось изменить пароль. Попробуйте еще раз. В случае неудачи обратитесь в службу поддержки.',
   change_password_form: 'Форма замены пароля',
   new_password: 'Новый пароль',
   new_password_repeate: 'Повтор пароля',
   done: 'Готово',
   change_username_form: 'Форма замены имени пользователя',
   passwort_is_not_matched: 'Пароли не совпадают',
   username_changed_succesfully: 'Изменение имени пользователя прошло успешно',
   password_changed_succesfully: 'Изменение пароля прошло успешно',
   changing_failed: "Ошибка, попробуйте еще раз"

}

const calculator = {
    candy_type: 'Тип конфет',
    title: 'Калькулятор',
    simple: 'Простой',
    clever: 'Умный',
    save_to_db: 'Сохранить в базу',
    border_value: 'Крайние значения',
    real_value: 'Введенные значения',
    ganasge_chart_title: 'Ганаш',
    candy_chart_title: 'Корпусные конфеты',
    truffel_chart_title: 'Трюфельные конфеты',
    clever_calc_before_start: 'Для создания рецепта будут предложены продуткы из базы данных. ' +
                              'Если вы хотите использовать собсвенные продукты, вы можете добавить их ' +
                              'в базу данных разделе здесь: ',
    trufel: 'Трюфель',
    case_candy: 'Корпусная конфета',
    own_candy: 'Своя конфета',
    ganashe: 'Ганаш',
    sweeteners: 'Сахара',
    sweeteners_comment: 'По умолчанию калькулятор сам предложит оптимальные сахара и их пропорции. ' +
                        'Вы так же можете задать ингредиенты и пропорции самостоятельно, для этого отметьте соответствующие чек-боксы.',
    choose_custom_ingredients: 'Выбрать ингредиенты самостоятельно',
    put_custom_propotion: 'Указать пропорции самостоятельно',
    add_ingred: 'Добавить ингредиент',
    main_proportion: 'Процент от общей массы',
    wet_ingred: 'Содержащие жидкость ингредиенты',
    wet_ingred_comment: 'Выберете жидксодержащие ингреденты. Это ингредиенты, в которых ' +
                        'значительный процент состовляет вода. Мы отдесли в эту группу ' +
                        'молочные продукты, фруктовые пюре, ореховые массы и прочие жидкости.',
    chocolates: 'Шоколад',
    chocolate_titles: 'Выберите один или несколько видов шоколада. Укажите пропорции для них, если это нужно.',
    butters: 'Сливочные/растительные масла',
    butters_title: 'Выберите сливочные или растительные масла, которые вы хотите их использовать в рецепте.',
    alcohol: 'Алкоголь',
    alcohol_title: 'Выберете алкоголь, который хотите использовать',
    without_water: 'Порошки/сухие смеси',
    without_water_title: 'Выберете ингредиенты, состоящие преимущественно из Твердых веществ.',
    toggle_calcs: 'Рецепт содержит продукты. Если вы перейдете на другой тип калькулятора, то все изменения будут сброшены. Перейти на другой тип калькулятора ?',
    go_ahead: 'Перейти',
    stay: 'Остаться на текущей версии',
    reset_recipe: 'Новый рецепт'
}

const navigation = {
    main_page: 'Главная',
    calculator: 'Калькулятор',
    products: 'Продукты',
    recipes: 'Рецепты',
    login: 'Войти',
    contacts: 'Контакты',
    user_page: 'Личный кабинет',
    singup: 'Зарегестрироваться'
}

export default {main_name, common_error_message, no_connection_error_message, product_save_succesfully, product_update_succesfully, login, succsesfull_change, email_confirmation, calculate, total, no_data,
                new_m, new_s, new_w, cancel, save, remove, change, search, category, recipes_not_found,
                components, gramm, gramms, suffix_gramm, one_product, products, not_saved_one, not_saved_several,
                saved_it_before_recipe, saved_them_before_recipe, user, calculator, next,  return_back,
                categories, stop_products, others, product, need_value, not_unique_name, show, reset, 
                recipe, back, settings, recipe_is_not_exists, changes_saved, product_created, navigation}