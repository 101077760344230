import Axios from 'axios';
import Vue from 'vue';
// import { compile } from 'vue/types/umd';
import Vuex from 'vuex';
import router from '../rout.js'

Axios.defaults.withCredentials = true
Axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT

const state = {
  products: null,
  product_types: null,
  recipes: null,
  user: null,
  is_authorized: null,
  alert_message: null,
  alert_color: null,
  loading: false
};

const getters = {
  PRODUCTS: state => {
    return state.products;
  },
  PRODUCT_TYPES: state => {
    return state.product_types;
  },
  RECIPES: state => {
    return state.recipes
  },
  USER: state => {
    return state.user
  },
  IS_AUTHORIZED: state => {
    return state.is_authorized
  },
  // inner
  ALERT_MESSAGE: state => {
    return state.alert_message
  },

  ALERT_COLOR: state => {
    return state.alert_color
  },

  LOADING: state => {
    return state.loading
  }
};

const mutations = {
  initializeStore() {
    state.is_authorized = localStorage.getItem('is_authorized') == 'true' ? true : false
  },

  RAISE_ALERT: (state, payload) => {
    state.alert_message = payload.message
    state.alert_color = payload.status
    setTimeout(function(){
      state.alert_message = null
      state.alert_color = null
    }.bind(state), 8000);
  },

  SET_LOADING: (state, payload) => {
    state.loading = payload
  },

  SET_USER: (state, payload) => {
    state.user = payload;
  },

  SET_PRODUCTS: (state, payload) => {
    state.products = payload;
  },

  ADD_PRODUCT: (state, payload) => {
    payload['product_type'] = payload['product_type']['name'];
    state.products[payload['product_type']].push(payload);
  },

  UPDATE_PRODUCT: (state, payload) => {
    state.recipes = null
    payload['product_type'] = payload['product_type']['name'];

    if (state.products == null) {
      return
    }
    let prIndex = -1
    state.products[payload['product_type']].forEach((element, index) => {
      if (element.id == payload['id']) {
        prIndex = index
      }
    }, prIndex, payload);
    
    if (prIndex > -1) {
      state.products[payload['product_type']][prIndex] = payload;
    }
  },

  UPDATE_PRICE: (state, payload) => {
    if (state.products != null) {
      let id = payload['product_id']
      let product_type = ''
  
      let prIndex = -1
      Object.entries(state.products).forEach(([, products]) => {
        products.forEach((info, index) => {
          if (info.id == id) {
            prIndex = index
            product_type = info.product_type
          }
        }, id)
      }, id)

      if (prIndex != -1) {
        state.products[product_type][prIndex]['price'] = payload['price'];
        state.products[product_type][prIndex]['price_weight'] = payload['weight'];
      }
    }
  },

  REMOVE_PRODUCT: (state, payload) => {
    if (state.products == null) {
      return
    }
    let id = payload['id']
    let product_type = payload['product_type']

    let prIndex = -1
    state.products[product_type].forEach((info, index) => {
      if (info.id == id) {
        prIndex = index
        product_type = info.product_type
      }
    }, id)

    state.products[product_type].splice(prIndex, 1);
  },

  RECIPES_CHANGED: (state, ) => {
    state.recipes = null
  },

  SET_RECIPES: (state, payload) => {
    state.recipes = payload;
  },

  SET_RECIPES_AMOUNT: (state, payload) => {
    state.recipes_amount = payload;
  },

  SET_PRODUCT_TYPES: (state, payload) => {
    state.product_types = payload;
  },

  REMOVE_RECIPE: (state, payload) => {
    if (state.recipes) {
      let newState = state.recipes
      let forRemove = -1
      for (let i = 0; i < newState.length; i++) {
        if (newState[i].id == payload.id) {
          forRemove = i
          break;
        }
      }
      newState.splice(forRemove, 1);
      state.recipes = newState
    }
  },

  REMOVE_RECIPES: (state, payload) => {
    if (state.recipes) {
      let newState = state.recipes
      let forRemove = []
      for (let i = 0; i < newState.length; i++) {
        if (newState[i].id == payload.id) {
          forRemove.push(i)
        }
      }
      for (let id in forRemove) {
        newState.splice(id, 1);
      }
      state.recipes = newState
    }
  },

  SET_AUTHORIZED_STATUS: (state, payload) => {
    state.is_authorized = payload
  },

  SET_USER_PROFILE: (state, payload) => {
    state.user = payload
  },

  SET_USER_USERNAME: (state, payload) => {
    if (state.user) {
      state.user.username = payload['new_username']
    }
  },

  SET_USER_NAME: (state, payload) => {
    if (state.user) {
      if ('new_first_name' in payload) {
        state.user.first_name = payload['new_first_name']  
      }
      if ('new_last_name' in payload) {
        state.user.last_name = payload['new_last_name']
      }
    }
  },

  LOGOUT: (state, ) => {
    state.products = null
    state.product_types = null
    state.recipes = null
    state.user = null
  }
};

const actions = {
  GET_PRODUCTS: async (context) => {
    let res = await Axios.get('api/v1/products/');
    if (res && res.status == 200) {
      context.commit('SET_PRODUCTS', res.data); 
    }
    return res
  },

  GET_PRODUCT: async (_, payload) => {
    let id = payload["id"]
    let res = await Axios.get(`api/v1/product/${id}`);
    return res
  },

  UPDATE_PRODUCT: async (context, payload) => {
    let id = payload["id"]
    let res = await Axios.put(`api/v1/product/${id}`, payload);
    if (res.status == 200) {
      context.commit('UPDATE_PRODUCT', res.data);
    }
    return res
  },

  ADD_PRODUCT: async (context, payload) => {
    const res = await Axios.post('api/v1/product/',  payload);
    if (res.status == 201) {
      context.commit('ADD_PRODUCT', res.data);
    }
    return res
  },

  REMOVE_PRODUCT: async (context, payload) => {
    let id = payload["id"]
    let res = await Axios.delete(`api/v1/product/${id}`)
    if (res.status == 200) {
      context.commit('REMOVE_PRODUCT', payload);
    }
    return res
  },

  UPDATE_PRICE: async (context, payload) => {
    let res = await Axios.post('api/v1/product_price/', payload);
    if (res.status == 200) {
      context.commit('UPDATE_PRICE', payload);
    }
    return res
  },

  GET_PRODUCT_TYPES: async (context) => {
    let res = await Axios.get('types_list/');
    if (res.status == 200) {
      context.commit('SET_PRODUCT_TYPES', res.data);
    }
    return res
  },

  GET_RECIPES: async (context) => {
    // short info about all recipes
    let res = await Axios.get('api/v1/recipes/');
    if (res.status == 200) {
      context.commit('SET_RECIPES', res.data);
    }
    return res
  },

  ADD_RECIPE: async (context, payload) => {
    const res = await Axios.post('api/v1/recipe/',  payload)
    if (res.status == 201) {
      context.commit('RECIPES_CHANGED');
    }
    return res
  },

  UPDATE_RECIPE: async (context, payload) => {
    let id = payload['id']
    const res = await Axios.put(`api/v1/recipe/${id}`,  payload)
    if (res.status == 200) {
      context.commit('RECIPES_CHANGED');
    }
    return res
  },

  GET_RECIPE: async (_, payload) => {
    let id = payload
    const res = await Axios.get(`api/v1/recipe/${id}`)
    return res
  },

  REMOVE_RECIPE: async (context, payload) => {
    let id = payload['id']
    const res = await Axios.delete(`api/v1/recipe/${id}`)
    if (res.status == 200) {
      context.commit('REMOVE_RECIPE', payload);
    }
    return res
  },

  REMOVE_RECIPES: async (context, payload) => {
    let res = await Axios.delete(`api/v1/recipes/`, {'data': payload})
    if (res.status == 200) {
      context.commit('REMOVE_RECIPES', payload);
    }
    return res
  },

  SINGUP_USER: async (_, payload) => {
    const res = await Axios.post('api/v1/users/', payload)
    return res
  },

  LOGIN_USER: async (context, payload) => {
    let res = await Axios.post(`api/v1/token/`, payload)
    if (res.status == 200) {
      context.commit('SET_AUTHORIZED_STATUS', true);
      localStorage.setItem('is_authorized', true)
    }
    return res
  },

  REFRESH_TOKEN: async (context, ) => {
    let res = await Axios.post(`api/v1/token/refresh/`)
    if (res.status == 200) {
      context.commit('SET_AUTHORIZED_STATUS', true);
      localStorage.setItem('is_authorized', true)
    }
    return res
  },

  GET_USER_PROFILE: async (context, ) => {
    let res = await Axios.get(`api/v1/users/me/`)
    if (res.status == 200) {
      context.commit('SET_USER_PROFILE', res.data);
    }
    return res
  },

  LOGOUT: async (context, ) => {
    let res = await Axios.post(`api/v1/logout/`)
    context.commit('SET_AUTHORIZED_STATUS', false);
    localStorage.setItem('is_authorized', false)
    context.commit('LOGOUT');
    return res
  },

  SENT: async () => {
    let res = await Axios.post(`api/v1/email/`)
    return res
  },

  ACTIVATE: async (_, payload) => {
    let res = await Axios.post(`api/v1/users/activation/`, payload)
    return res
  },

  RESET_PASSWORD: async (_, payload) => {
    let res = await Axios.post(`api/v1/users/reset_password/`, payload)
    return res
  },

  CHANGE_PASSWORD: async (_, payload) => {
    let res = await Axios.post(`api/v1/users/set_password/`, payload)
    return res
  },

  CONFIRM_RESET_PASSWORD: async (_, payload) => {
    let res = await Axios.post(`api/v1/users/reset_password_confirm/`, payload)
    return res
  },

  CHANGE_USERNAME: async (context, payload) => {
    let res = await Axios.post(`api/v1/users/set_username/`, payload)
    if (res.status == 200 || res.status == 204) {
      context.commit('SET_USER_USERNAME', payload);
    }
    return res
  },

  CHANGE_EMAIL: async (_, payload) => {
    let res = await Axios.post(`api/v1/set_email/`, payload)
    return res
  },

  CHANGE_NAME: async (context, payload) => {
    let res = await Axios.post(`api/v1/set_name/`, payload)
    if (res.status == 200 || res.status == 204) {
      context.commit('SET_USER_NAME', payload);
    }
    return res
  },

  CONFIRM_CHANGE_EMAIL: async (context, payload) => {
    let res = await Axios.post(`api/v1/confirm_set_email/`, payload)
    return res
  },

  RESET_USERNAME: async (_, payload) => {
    let res = await Axios.post(`api/v1/users/reset_username/`, payload)
    return res
  },

  CONFIRM_RESET_USERNAME: async (_, payload) => {
    let res = await Axios.post(`api/v1/users/reset_username_confirm/`, payload)
    return res
  },

  DELETE_USER: async (_, payload) => {
    let res = await Axios.delete(`api/v1/users/me/`, {'data': payload})
    return res
  },

  CALCULATE: async (_, payload) => {
    let res = await Axios.post(`api/v1/calculator/`, payload)
    return res
  },
  
};

// export default {
//   state,
//   getters,
//   mutations,
//   actions,
// };

Vue.use(Vuex)
let store = new Vuex.Store({
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
  // modules: {
  //   calc_store,
  // },
});

export default store

Axios.interceptors.response.use(response => {
  store.commit("SET_LOADING", false)
  return response;
}, error => {
  store.commit("SET_LOADING", false)
  const originalConfig = error.config;
  // refresh and get access/refresh first time
  if (!originalConfig.url.includes("api/v1/token") && error.response) {
    switch (error.response.status) {    
      // Вернуть 401, очистить информацию о токене и перейти на страницу входа
      case 401:
        if (originalConfig._retry) {
          localStorage.setItem('is_authorized', false)
          store.commit('SET_AUTHORIZED_STATUS', false);
          return Promise.reject(error);
        } else {
          return store.dispatch('REFRESH_TOKEN')
            // eslint-disable-next-line camelcase
            .then(() => {
              originalConfig._retry = true;
              return Axios.request(originalConfig); 
            });
        }
        // break;
      // case 404:
      //   router.push('/NotFound'
      //     // Перейти на текущую страницу после успешного входа
      //     // query: {redirect: router.currentRoute.fullPath}
      //   )
      //   break;
      // case 500:
      //   // localStorage.removeItem('token');
      //   router.replace({
      //     path: '/ServerError'
      //     // Перейти на текущую страницу после успешного входа
      //     // query: {redirect: router.currentRoute.fullPath}
      //   })
      //   break;
    }
  } else if (originalConfig.url.includes("api/v1/token/refresh") && error.response) {
    localStorage.setItem('is_authorized', false)
    store.commit('SET_AUTHORIZED_STATUS', false);
    router.replace({
      path: '/login'
      // Перейти на текущую страницу после успешного входа
      // query: {redirect: router.currentRoute.fullPath}
    })
    return Promise.reject(error);
  } else {
    localStorage.setItem('is_authorized', false)
    store.commit('SET_AUTHORIZED_STATUS', false);
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

Axios.interceptors.request.use(response => {
  store.commit("SET_LOADING", true)
  return response;
}, error => {
  return Promise.reject(error);
});


  // export default createStore({
  //   state,
  //   getters,
  //   mutations,
  //   actions
  //   // modules: {
  //   //   calc_store,
  //   // },
  // });

  

  