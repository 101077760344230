<template>
  <v-container>
    <div class="component"></div>
    <span class="component-title">
      <span>
        <img class="name-icon name-icon-align" src="./icons/star_big.svg" />
        <span style="z-index: 20; font-size: 26px; position: relative">{{ text.recipe.proportion }}</span>
      </span>
      <span style="justify-self: end;">
        <img class="cropped" src="./icons/star_big.svg" />
        <img class="star-icon" src="./icons/star_small.svg" />
      </span>
    </span>

    <div class="scroll-table-conteiner">
      <table style="border: none;">
        <tbody>
          <tr style="height: 50px">
            <th
              v-for="component in headers"
              :key='"header_" + component.text'>
              {{ component.text }}
            </th>
          </tr>

          <tr
            v-for="product in productsProportionTable"
            :key='"row_" + product.name' style="height: 50px;">
            <td
              v-for="component in headers"
              :key='"cell_" + component.value + product.name'
              :style="component.value == 'name' ? 'text-align: start; padding-left: 10px' : 'text-align: center;'">
              {{ product[component.value] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-container>
</template>
<script>
import { COMPONENT_NAME, TABLE_ICONS } from './define.js'
import all_text from './ru-text'
 export default {
    props: {
      products: {
        Object
      },
      saveFinalRecipeProportion: {
        type: Function
      }
    },
    name: 'ProductsProportionTable',
    data() {
      return {
        headers: [
          { text: all_text.components.name, align: 'start', value: 'name'},
          { text: all_text.components.ratio, value: 'ratio' },
          { text: all_text.components.solid_fats, value: 'solid_fats' },
          { text: all_text.components.sugar, value: 'sugar' },
          { text: all_text.components.soft_fats, value: 'soft_fats' },
          { text: all_text.components.water, value: 'water' },
          { text: all_text.components.alcohol, value: 'alcohol' },
          { text: all_text.components.d_s, value: 'd_s' },
        ],

        table_icons: TABLE_ICONS,

        componentsName: COMPONENT_NAME,
        productsProportionTable: [],
        text: all_text
      }
    },
    watch: {
      products(newProducts) {
        this.calcProductsProportionTable(newProducts)
      }
    },
    created() {
      this.calcProductsProportionTable(this.products)
    },
    methods: {
      calcProductsProportionTable(newProducts) {
        this.recipeSaved = false
        let sumComponents = {}
        this.productsProportionTable = newProducts.map(function(product) {
          let ratio = parseFloat(product['amount']) * 100 / parseFloat(newProducts.at(-1)['amount'])
          console.log(product['name'], "ration", ratio, "total am", newProducts.at(-1)['amount'], 'amout', product['amount'])
          let newItem = {'name': product['name'], 'ratio': ratio.toFixed(2)}
          Object.entries(this.componentsName).forEach(([component, ]) => {
            console.log(component, 'pr comp', product[component])
            if (product['name'] == this.text.total) {
              newItem[component] = sumComponents[component].toFixed(2)
            } else {
              let temp = parseFloat(product[component]) * ratio / 100
              newItem[component] = temp.toFixed(2)
              sumComponents[component] = sumComponents[component] ? 
                                          sumComponents[component] + temp : 
                                          temp
            }
          }, this, sumComponents)
          return newItem
        }.bind(this), sumComponents);
        this.saveFinalRecipeProportion(sumComponents)
      }
    }
 }
</script>

<style>

table {
  border: 1px solid #ccc5c5;
}

.name-icon {
  position: absolute;
  z-index: 1;
  opacity: 0.7;
}

.name-icon-align {
  height: 88px;
  width: 88px;
  margin-top: -25px
}

table {
  border-collapse: collapse;
  width: 100%;
  border: none;
}

th, td {
  border-bottom: 3px solid var(--v-green-base);
}

tr:hover {
  background-color: var(--v-main_background2-base);
}

.component {
  height: 100px;
  width: 100%;
  left: 50%;
  margin-left: -50%;
  position: absolute;
  background-color: var(--v-green-base);
}

.component-title {
  height: 100px;
  display: grid;
  z-index: 10;
  position: relative;
  color: var(--v-main_background-base);
  font-size: 16px;
  grid-template-columns: 50% 50%;
  align-items: center;
}

.cropped {
  width: 110px;
  height: 50px;
  top: 0;
  object-fit: cover;
  object-position: top;
  transform: translate(0, 55%);
}

.star-icon {
  height: 50px;
  width: 50px;
}

.scroll-table-conteiner {

}

@media (max-width: 950px) {

  .scroll-table-conteiner {
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 10px;
    /* border: solid black 1px; */
    font-size: 13px;
  }

  .scroll-table-conteiner table td:first-child, .scroll-table-conteiner table th:first-child {
    position: sticky;
    left: 0;
    background-color: var(--v-main_background-base);
  }

  .scroll-table-conteiner td, .scroll-table-conteiner th {
    border-bottom: 3px solid var(--v-green-base);
    padding-left: 5px;
    padding-right: 5px;
  }
}

</style>
