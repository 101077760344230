<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center class='mt-10'>
      <v-flex xs12 sm8 md6>
        <v-card class="elevation-7" color="main_background">
          <v-toolbar color="green">
            <v-toolbar-title>
              <span class="user-dialog-title">
                {{ text.login_form_name }}
              </span>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                  class='my-2'
                  outlined
                  :label="text.login_email_label"
                  v-model="userName"
                  type="text"
                  append-icon="mdi-account-circle-outline"
                  @click="resetLogInErrMessage()"/>
              <v-text-field
                  outlined
                  name="password"
                  :label="text.password_label"
                  v-model="password"
                  :type="showMainPassword ? 'text' : 'password'"
                  :append-icon="showMainPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showMainPassword = !showMainPassword"
                  @click="resetLogInErrMessage()"/>
            </v-form>
            <div :class="login_message_class">
              {{ login_error_message }}
            </div>
          </v-card-text>
          <v-card-actions>
            <button class="common-button login-button"
                @click="() => { reset_password_dialog = true }">
              {{ text.foget_password }}
            </button>
            <v-spacer></v-spacer>
            <button @click="logIn()" class="common-button login-button">
              {{ text.enter }}
            </button>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
        v-model="reset_password_dialog"
        persistent
        :fullscreen="$vuetify.breakpoint.width < mobile_border"
        max-width="50%">
      <v-card class="elevation-12" color="main_background">
        <v-toolbar color="green">
          <v-toolbar-title>
            <span class="user-dialog-title">
              {{ text.reset_password_form }}
            </span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form v-model="valid" ref="reset_form">
            <v-text-field
                style="margin-top: 20px"
                :label="text.email_label"
                v-model="email_for_reset"
                :rules="[rules.email, rules.required]"
                type='text'
                @click="reset_message_class = 'err-msg-hide'"/>
          </v-form>
        </v-card-text>
        <v-card-text :class="reset_message_class">
          {{ reset_message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <button :disabled="!valid || $store.getters.LOADING"
              :style="!valid || $store.getters.LOADING ? 'opacity: 60%' : ''"
              class="common-button login-forgot-pswd-button"
              @click="resetCredetials()">
            {{ text.sent_email }}
          </button>
          <button class="common-button login-forgot-pswd-button"
              style="right: 16px !important"
              @click="() => {reset_password_dialog = false;
                              this.$refs.reset_form.reset()}">
              {{ text.cancel }}
          </button>
      </v-card-actions>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ALERT_TYPE, MOBILE_BORDER } from './define.js'
import all_text from './ru-text'
import ALL_RULES from './rules'
export default {
  name: 'Login',
  data() {
    return {
      userName: null,
      password: null,
      showMainPassword: false,
      login_error_message: 'good',
      login_message_class: "err-msg-hide",
      reset_password_dialog: false,
      email_for_reset: null,
      reset_message: 'good',
      reset_message_class: "err-msg-hide",
      valid: false,

      text: all_text.login,
      rules: ALL_RULES,
      mobile_border: MOBILE_BORDER,
    }
  },
  methods: {
    logIn() {
      this.$store.dispatch('LOGIN_USER',
                              {'password': this.password,
                                'username': this.userName}).then(() => {
        this.$router.push('/calculator/');
      }).catch(() => {
        this.login_error_message = this.text.wrong_login_or_password
        this.login_message_class = "err-msg-show"
      });
    },
    resetCredetials() {
      this.$store.dispatch('RESET_PASSWORD', {'email': this.email_for_reset}).then((res) => {
        if (res.status == 204) {
          this.$store.commit("RAISE_ALERT",
                              {'message': this.text.link_email_sent,
                              'status': ALERT_TYPE.SUCCESS})
          this.reset_password_dialog = false
          this.email_for_reset = null
        }
      });
      // sofyabalandina1596@gmail.com
      // .catch((err) => {
      //   this.reset_password_dialog = false
      //   this.reset_message = ''
      //   err.response.data.forEach(err_text => {
      //     if (err_text == 'User with given email does not exist.') {
      //       this.reset_message = this.text.wrong_email     
      //     }
      //   });
      //   if (this.reset_message == '') {
      //     this.reset_message = this.text.somthing_went_wrong
      //   }
      //   this.login_message_class = "err-msg-show"
      // });
    },
    resetLogInErrMessage() {
      this.login_message_class = "err-msg-hide"
    }
  }
};
</script>
<style>
.login-button {
  padding: 3px 15px;
}

.login-forgot-pswd-button {
  position: absolute;
  right: 126px;
  bottom: 16px;
  padding: 3px 15px;
}
</style>
