<template>
  <v-container>
    <div class="component"></div>
    <span class="component-title">
      <span>
        <img class="name-icon name-icon-align" src="./icons/star_big.svg" />
        <span style="z-index: 20; font-size: 26px; position: relative">{{ text.recipe.ingredients }}</span>
      </span>
      <span style="justify-self: end;" v-if="isChanging">
        <!-- :class="$store.getters.LOADING ? ['common-disable-text-color'] : []" -->
        <button
          style="display: flex; align-items: center;"
          @click="onAddNewProduct()"
          :disabled="$store.getters.LOADING"
          >
          {{ text.recipe.add_product }}
          <img
            style="margin-left: 10px"
            src="./icons/add_new_product.svg"
            height="30px"
            width="30px" />
        </button>
      </span>
    </span>

    <div class="scroll-table-conteiner">
      <table style="border: none;">
        <tbody>
          <tr style="height: 70px">
            <th v-for="component in headers" :key='"header_" + component.text'>{{ component.text }}</th>
            <th v-if="isChanging"></th>
          </tr>

          <tr v-for="(product, idx) in products" :key='"row_" + product.name' style="height: 50px;">
            <td
              v-for="component in headers"
              :key='"cell_" + component.value + product.name'
              :style="component.value == 'name' ? 'text-align: start; padding-left: 10px' : 'text-align: center;'">
              <span v-if="isChanging && component.value == 'amount' && product.name != 'Всего'"
                  class="update-recipe-val">
                <v-text-field
                  :rules="[rules.required, rules.double, rules.moreThenZero]"
                  @input="onEditAmount(idx)"
                  v-model="product[component.value]">
                </v-text-field>
              </span>
              <span v-else>
                {{ round_float(product[component.value]) }}
              </span>
            </td>

            <td style="width: 40px;" v-if="isChanging">
              <span style="display: flex" v-if="product.name != text.total">
                <button
                  v-if="saveStatusMap[idx] != saveStatuses.N_A"
                  @click="onEditProduct(product, idx)"
                  :disabled="$store.getters.LOADING">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        src="./icons/product_edit.svg"
                        style="cursor: pointer; margin: 5px"
                        height="20px"
                        width="20px"
                        v-bind="attrs"
                        v-on="on" />
                    </template>
                    <span>{{ text.recipe.change_product }}</span>
                  </v-tooltip>
                </button>
                <button
                  v-if="saveStatusMap[idx] != saveStatuses.N_A"
                  @click="onSaveProductToDB(product, idx)"
                  :style="$store.getters.LOADING || saveStatusMap[idx] == saveStatuses.SAVED ? 'opacity: 40%' : ''"
                  :disabled="$store.getters.LOADING || saveStatusMap[idx] == saveStatuses.SAVED">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        src="./icons/save.svg"
                        style="margin: 5px;"
                        height="20px"
                        width="20px"
                        v-bind="attrs"
                        v-on="on" />
                    </template>
                    <span>{{ text.recipe.save_product_to_db }}</span>
                  </v-tooltip>
                </button>
                <button
                  @click="onDeleteProduct(idx)"
                  :disabled="$store.getters.LOADING">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        src="./icons/product_remove.svg"
                        style="cursor: pointer;  margin: 5px"
                        height="20px"
                        width="20px"
                        v-bind="attrs"
                        v-on="on" />
                    </template>
                    <span>{{ text.recipe.remove_product }}</span>
                  </v-tooltip>
                </button>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <NewIngredient v-if="openProductDialog"
            :changeMode="this.changingPrIDx != -1"
            :closeDialog="onCloseNewIngredientDialog" 
            :productItem="productItem" 
            :dialog="openProductDialog || changingPrIDx != -1"/>

    <CreateUpdateProduct v-if="openNewProductDialog == true" 
            :changeMode="changingPrIDx != -1 &&
                         saveStatusMap[changingPrIDx] == saveStatuses.EDITED_NOT_SAVED"
            :closeDialog="onCloseSaveNewProductDialog" 
            :productItem="productItem" 
            :dialog="openNewProductDialog"/>

<!-- REMOVe -->
  </v-container>
</template>
<script>
import NewIngredient from './NewIngredient.vue'
import CreateUpdateProduct from './CreateUpdateProduct.vue'
import { COMPONENT_NAME, SAVE_STATUS, TABLE_ICONS, MOBILE_BORDER, TABLET_BORDER } from './define.js'
import all_text from './ru-text'
import all_rules from './rules'
export default {
  props: {
    isChanging: {
      type: Boolean,
      default: true
    },
    products: {
      Object
    },
    addProduct: {
      Function
    },
    updateProduct: {
      Function
    },
    removeProduct: {
      Function
    }
  },
  name: 'IgredientsTable',
  components: {
    NewIngredient,
    CreateUpdateProduct
  },
  data() {
      return {
        openNewProductDialog: false,

        mobile_border: MOBILE_BORDER,
        tablet_border: TABLET_BORDER,

        headers: [
          { text: all_text.components.name, align: 'start', value: 'name'},
          { text: all_text.components.amount, value: 'amount' },
          { text: all_text.components.solid_fats, value: 'solid_fats' },
          { text: all_text.components.sugar, value: 'sugar' },
          { text: all_text.components.soft_fats, value: 'soft_fats' },
          { text: all_text.components.water, value: 'water' },
          { text: all_text.components.alcohol, value: 'alcohol' },
          { text: all_text.components.d_s, value: 'd_s' },
        ],

        table_icons: TABLE_ICONS,

        openProductDialog: false, 
        changingPrIDx: -1,
        productItem: {},

        componentsName: COMPONENT_NAME,
        saveStatuses: SAVE_STATUS,

        saveStatusMap: [],

        text: all_text,
        rules: all_rules
      }
    },
  watch: {
    products() {
      // STRANGE THING: after changing component saveStatusMap become []
      // but it is okay, it affect only developer
      if (this.saveStatusMap.length == 0) {
        this.products.forEach(() => {
          this.saveStatusMap.push(SAVE_STATUS.N_A)
        });
      }
    },
  },
  methods: {
    onEditAmount() {
      let totalAmount = this.products.reduce((accum, item) => 
                        { let temp = item.name == this.text.total ? 0 : item.amount;
                          temp = item.amount ? parseFloat(temp) : 0;
                          return parseFloat(accum) + temp}, 0)
      this.updateProduct(-1, totalAmount, 'amount')
    },
    onAddNewProduct() {
      this.productItem = {'product_type': undefined,
                          'toggleVariant': 0, 
                          'name': undefined,
                          'firm': undefined,
                          'ratio': undefined},
      Object.keys(COMPONENT_NAME).forEach(component => {
          this.productItem[component] = undefined
      });

      this.openProductDialog = true;
    },
    onEditProduct(item, idx) {
      this.productItem = JSON.parse(JSON.stringify(item)) 
      this.changingPrIDx = idx
      this.openProductDialog = true;
    },
    onSaveProductToDB(item, idx) {
      this.productItem = JSON.parse(JSON.stringify(item)) 
      this.changingPrIDx = idx
      this.openNewProductDialog = true
    },
    onCloseSaveNewProductDialog(isSave, isSuccsess, newProduct) {
      if (isSave && isSuccsess) {
        this.onCloseSaveProductDialog(true, true, newProduct, this.changingPrIDx)
      }
      this.openNewProductDialog = false;
      this.changingPrIDx = -1
      this.productItem = {}
    },
    onCloseSaveProductDialog(isSave, isSuccsess, newProduct, idx) {
      if (isSave && isSuccsess) {
        let updated = this.products[idx]
        Object.keys(newProduct).forEach((key, ) => {
            updated[key] = newProduct[key]
        })
        this.updateProduct(idx, updated)
        this.saveStatusMap[idx] = SAVE_STATUS.SAVED
      }
    },
    onCloseNewIngredientDialog(newProduct, saveStatus=false) {
      if (saveStatus) {
        // new item
        if (this.changingPrIDx == -1) {
          // add Всего or recalculate it 
          if (this.products.length > 0 && this.products.at(-1)['name'] == this.text.total) {
            // if user have deviced to add product already in recipe
            let temp_idx = null;
            this.products.every((element, idx) => {
              temp_idx = (element.id && element.id == newProduct.id) ? idx : null
              return element.id != newProduct.id })
            if (temp_idx != null) {
              // if already exist in table, just recalculate product amount
              let temp_amout = parseFloat(this.products[temp_idx]['amount']) +
                               parseFloat(newProduct['amount'])
              this.updateProduct(temp_idx, temp_amout, 'amount')

              // update Total value
              temp_amout = parseFloat(this.products.at(-1)['amount']) +
                           parseFloat(newProduct['amount'])
              this.updateProduct(temp_idx, temp_amout, 'amount')
            } else {
              // apdate Total value
              let temp_amout = parseFloat(this.products.at(-1)['amount']) +
                               parseFloat(newProduct['amount'])
              this.updateProduct(-1, temp_amout, 'amount')
              // add product on the end, but before the Total
              this.addProduct(newProduct, -1)
              // toggleVariant == 1 if product created by user now
              this.saveStatusMap.splice(-1, 0,
                                          newProduct.toggleVariant == 1 ? 
                                          SAVE_STATUS.CREATED_NOT_SAVED : SAVE_STATUS.N_A)
            }
          } else {
            this.addProduct(newProduct)
            this.saveStatusMap.push(newProduct.toggleVariant == 1 ?
                                    SAVE_STATUS.CREATED_NOT_SAVED : SAVE_STATUS.N_A)
            this.addProduct({'name': this.text.total, 'amount': newProduct['amount']})
            this.saveStatusMap.push(SAVE_STATUS.N_A)
          }
        } else {
          // changed item
          let temp_amout = this.products.at(-1)['amount'] - parseFloat(this.products[this.changingPrIDx].amount)
          this.updateProduct(-1, temp_amout, 'amount')
          Object.assign(this.products[this.changingPrIDx], newProduct)
          temp_amout = this.products.at(-1)['amount'] + parseFloat(this.products[this.changingPrIDx].amount)
          this.updateProduct(-1, temp_amout, 'amount')
          this.saveStatusMap[this.changingPrIDx] = this.saveStatusMap[this.changingPrIDx] == SAVE_STATUS.SAVED ?
                                                    SAVE_STATUS.EDITED_NOT_SAVED :
                                                    this.saveStatusMap[this.changingPrIDx]
        }
      }
      this.openProductDialog = false
      this.changingPrIDx = -1
      this.productItem = {}
    },
    onDeleteProduct(index) {
      // deduct amount of product from Всего
      let amount = 0;
      if (index > -1) {
        amount = this.products[index].amount
        this.removeProduct(index);
        this.saveStatusMap.splice(index, 1)
      }
      // remove Всего, if nothing left except it
      if (this.products.length == 1) {
        this.removeProduct(0);
        this.saveStatusMap.splice(0, 1)
      } else {
        this.updateProduct(-1, this.products.at(-1).amount - amount, 'amount')
      }
    },
    round_float(value) {
      // console.log(value, parseFloat(value))
      if (parseFloat(value)) {
        return parseFloat(value).toFixed(2)
      } else {
        return value
      }
    }
  }
}
</script>
<style>

.name-icon {
  position: absolute;
  z-index: 1;
  opacity: 0.7;
}

.name-icon-align {
  height: 88px;
  width: 88px;
  margin-top: -25px
}

table {
  border-collapse: collapse;
  width: 100%;
  border: none;
}

th, td {
  border-bottom: 3px solid var(--v-green-base);
}

tr:hover {
  background-color: var(--v-main_background2-base);
}


.component {
  height: 100px;
  width: 100%;
  left: 50%;
  margin-left: -50%;
  position: absolute;
  background-color: var(--v-green-base);
}

.component-title {
  height: 100px;
  display: grid;
  z-index: 10;
  position: relative;
  color: var(--v-main_background-base);
  font-size: 16px;
  grid-template-columns: 50% 50%;
  justify-items: start;
  align-items: center;
}

.update-recipe-val>.theme--light.v-input, .update-recipe-val>.theme--light.v-input input, .update-recipe-val>.theme--light.v-input textarea {
  color: var(--v-green-base) !important;
  text-align: center !important;
  margin-left: 5px;
  margin-right: 5px;
}
.update-recipe-val .theme--light.v-label {
  color: var(--v-green-base) !important;
}

.update-recipe-val .theme--light.v-messages {
  color: var(--v-red-base) !important;
}

.update-recipe-val .v-text-field>.v-input__control>.v-input__slot:after, .v-text-field>.v-input__control>.v-input__slot:before {
  border-color: var(--v-green-base) !important;
}

.update-recipe-val .theme--light.v-text-field>.v-input__control>.v-input__slot:before {
  border-color: var(--v-green-base) !important;
}

.update-recipe-val .v-text-field .v-select__slot .v-icon {
  color: var(--v-green-base) !important;
}

.scroll-table-conteiner {

}

@media (max-width: 950px) {

  .scroll-table-conteiner {
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 10px;
    /* border: solid black 1px; */
    font-size: 13px;
  }

  .scroll-table-conteiner table td:first-child, .scroll-table-conteiner table th:first-child {
    position: sticky;
    left: 0;
    background-color: var(--v-main_background-base);
  }

  .scroll-table-conteiner td, .scroll-table-conteiner th {
    border-bottom: 3px solid var(--v-green-base);
    padding-left: 5px;
    padding-right: 5px;
  }
}

</style>
