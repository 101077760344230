<template>
  <v-container >
    <div style="display: grid; grid-template-columns: 25% 50% 25%;">
      <v-text-field
        id='recipeNameCleverCalc'
        :error-messages="recipeNameErrMsg == '' ? [] : [recipeNameErrMsg]"
        v-model="recipeName"
        :rules="[rules.required, custom_rules.recipeNameUnique, rules.string]"
        :label="text.recipe.recipe_name"
        style="grid-column-start: 2;"
        @click="() => {recipeNameErrMsg = ''}">
      </v-text-field>
    </div>

    <div class="before_start_notice">
      <div class="short-green-line"/>
      <div style="padding-left: 2px; padding-right: 2px; text-align: center;">
        *{{ text.calculator.clever_calc_before_start }}
        <a  href="http://127.0.0.1:8000/?#/products">Продукты</a>
      </div>
      <div class="short-green-line"/>
    </div>

    <v-stepper
        v-model="stepNumber"
        vertical elevation="3"
        style="background-color: var(--v-main_background-base); margin-bottom: 50px;">
      <v-stepper-step
          :complete="stepNumber > 1"
          color="orange"
          step="1">
        <div class="step-header-container">
          <div class="step-header-text">
            {{ text.calculator.candy_type }}
          </div>
          <div class="short-green-line" ></div>
        </div>
      </v-stepper-step>
      <v-stepper-content step="1">
        <div>
          <v-btn-toggle
            v-model="candy_type" class="toggle-buttons-clevel-calc" color="primary"
            style="background-color: var(--v-main_background-base)">
            <v-btn :class="candy_type == 0 ?
                    'active-toggle-green candy-type-btn' :
                    'not-active-toggle-green candy-type-btn'"
              style="border-style: solid; border-color: var(--v-green-base) !important; border-width: 1px;">
              {{ text.calculator.trufel }}
            </v-btn>
            <v-btn :class="candy_type == 1 ?
                    'active-toggle-green candy-type-btn' :
                    'not-active-toggle-green candy-type-btn'"
              style="border-style: solid; border-color: var(--v-green-base) !important; border-width: 1px 1px 1px 0px;">
              {{ text.calculator.case_candy }}
            </v-btn>
            <v-btn :class="candy_type == 2 ?
                    'active-toggle-green candy-type-btn' :
                    'not-active-toggle-green candy-type-btn'"
              style="border-style: solid; border-color: var(--v-green-base) !important; border-width: 1px 1px 1px 0px;">
              {{ text.calculator.ganashe }}
            </v-btn>
            <v-btn :class="candy_type == 3 ?
                    'active-toggle-green candy-type-btn' :
                    'not-active-toggle-green candy-type-btn'"
              style="border-style: solid; border-color: var(--v-green-base) !important; border-width: 1px 1px 1px 0px;">
              {{ text.calculator.own_candy }}
            </v-btn>
          </v-btn-toggle>
          <button
            class="common-button clever-calc-next-btn"
            @click="stepNumber = 2">
              {{ text.next }}
          </button>
        </div>
      </v-stepper-content>

        <v-stepper-step
          :complete="stepNumber > 2"
          color="orange"
          step="2">
          <div class="step-header-container">
            <div class="step-header-text">
              {{ text.calculator.sweeteners }}
            </div>
            <div class="short-green-line" ></div>
          </div>
        </v-stepper-step>
        <v-stepper-content step="2">
          <CleverIngredientsList
              :title="text.calculator.sweeteners_comment"
              :ratio_info="info.sweeteners_ratio"
              :optional_ingeds_setup='true'
              :categories="['сахар']"
              :setOwnPortionValue="candy_type == 3 ? (newVal) => { info.sweeteners_portion = newVal} : undefined"
              :onNext="() => stepNumber = 3"
              :onPrev="() => stepNumber = 1"/>
        </v-stepper-content>

        <v-stepper-step
          :complete="stepNumber > 3"
          color="orange"
          step="3">
          <div class="step-header-container">
            <div class="step-header-text">
              {{ text.calculator.wet_ingred }}
            </div>
            <div class="short-green-line" ></div>
          </div>
        </v-stepper-step>
        <v-stepper-content step="3">
          <CleverIngredientsList
              :title="text.calculator.wet_ingred_comment"
              :ratio_info="info.wet_ingredients_ratio"
              :categories="['ореховые пасты', 'фруктовые пюре', 'жидкости']"
              :optional_ingeds_setup='false'
              :onNext="() => stepNumber = 4"
              :onPrev="() => stepNumber = 2" />
        </v-stepper-content>

        <v-stepper-step
          :complete="stepNumber > 4"
          color="orange"
          step="4">
          <div class="step-header-container">
            <div class="step-header-text">
              {{ text.calculator.chocolates }}
            </div>
            <div class="short-green-line" ></div>
          </div>
        </v-stepper-step>
        <v-stepper-content step="4">
          <CleverIngredientsList
              :title="text.calculator.chocolate_titles"
              :ratio_info="info.chocolates_ratio"
              :categories="['шоколад']"
              :optional_ingeds_setup='false'
              :onNext="() => stepNumber = 5"
              :onPrev="() => stepNumber = 3"/>
        </v-stepper-content>

        <v-stepper-step
          :complete="stepNumber > 5"
          color="orange"
          step="5">
          <div class="step-header-container">
            <div class="step-header-text">
              {{ text.calculator.butters }}
            </div>
            <div class="short-green-line" ></div>
          </div>
        </v-stepper-step>
        <v-stepper-content step="5">
          <CleverIngredientsList
              :title="text.calculator.butters_title"
              :ratio_info="info.soft_oil_ratio"
              :categories="['мягкие масла', 'растительные масла']"
              :optional_ingeds_setup='false'
              :onNext="() => stepNumber = 6"
              :onPrev="() => stepNumber = 4"/>
        </v-stepper-content>

        <v-stepper-step
          :complete="stepNumber > 6"
          color="orange"
          step="6">
          <div class="step-header-container">
            <div class="step-header-text">
              {{ text.calculator.alcohol }}
            </div>
            <div class="short-green-line" ></div>
          </div>
        </v-stepper-step>
        <v-stepper-content step="6">
          <CleverIngredientsList
              :title="text.calculator.alcohol_title"
              :ratio_info="info.alcohol_ratio"
              :categories="['алкоголь']"
              :optional_ingeds_setup='true'
              :onNext="() => stepNumber = 7"
              :onPrev="() => stepNumber = 5" />
        </v-stepper-content>

        <v-stepper-step
          :complete="stepNumber > 7"
          color="orange"
          step="7">
          <div class="step-header-container">
            <div class="step-header-text">
              {{ text.calculator.without_water }}
            </div>
            <div class="short-green-line" ></div>
          </div>
        </v-stepper-step>
        <v-stepper-content step="7">
          <CleverIngredientsList
              :title="text.calculator.without_water_title"
              :ratio_info="info.dry_ingredients_ratio"
              :categories="['сыпучие продукты']"
              :stop_components="['solid_fats', 'soft_fats', 'water', 'alcohol']"
              :optional_ingeds_setup='true'
              :onNext="buildRecipe"
              :onPrev="()=> stepNumber = 6"/>
        </v-stepper-content>

        <!-- <v-stepper-step
            :complete="stepNumber > 9"
            editable
            step="9">
            Другое
        </v-stepper-step>
        <v-stepper-content step="9">
            <CleverIngredientsList
                title="Выберире что-то еще"
                :ratio_info="info.sweeteners_ratio"
                :categories="['другое']"
                :stop_components="['solid_fats', 'soft_fats', 'water', 'alcohol']"
                :full_proportion="info.sweeteners_portion"
                :onNext="buildRecipe"
                :onPrev="() => stepNumber = 7"/>
        </v-stepper-content> -->
      </v-stepper>

      <IngredientsTableForRecipe
        id='ingredientsTableClevelCalc'
        style="margin-bottom: 30px;"
        :isChanging="false"
        :products="products"/>

      <ProductsProportionTable
        :products="products"
        :saveFinalRecipeProportion="saveFinalRecipeProportion"/>

      <span class="recipe-info-tables-container">
        <div class="recipe-wave-container">
          <img class="recipe-wave"/>
        </div>

        <CalcAmountTable
          style="z-index: 5"
          :products="products"
          :recipeName="recipeName"
          :amountTable="amountTable"/>

        <div class="simple-calc-db-save-border" style="z-index: 5">
          <div class="simple-calc-db-save-button-container">
            <button
              class="simple-calc-db-save-button common-button"
              :style="$store.getters.LOADING || products.length == 0 || recipeSaveStatus == save_status.SAVED ?
                    'opacity: 60%': ''"
              @click="saveRecipe()"
              :disabled="products.length == 0 || $store.getters.LOADING || recipeSaveStatus == save_status.SAVED">
              {{ text.calculator.save_to_db }}
            </button>
            <button
              class="simple-calc-db-save-button common-button"
              :style="$store.getters.LOADING || (products.length == 0 && stepNumber == 1) ?
                    'grid-column-start: 2; opacity: 60%;': 'grid-column-start: 2;'"
              @click="resetRecipe()"
              :disabled="$store.getters.LOADING || (products.length == 0 && stepNumber == 1)">
              {{ text.calculator.reset_recipe }}
            </button>
            <div class="simple-calc-db-save-star">
              <img class="simple-calc-db-save-star-icon" src="./icons/star_big.svg" />
            </div>
          </div>
        </div>
      </span>
  </v-container>
</template>
<script>
import IngredientsTableForRecipe from './IngredientsTableForRecipe.vue'
import ProductsProportionTable from './ProductsProportionTable.vue'
import CalcAmountTable from './CalcAmountTable.vue'

import CleverIngredientsList from './CleverIngredientsList.vue'
import { COMPONENT_NAME, ALERT_TYPE, SAVE_STATUS } from './define.js'
import all_rules from './rules'
import all_text from './ru-text'
import saveReceptToDB from './save_recipe_to_db'

export default {
  name: 'Calculator',
  components: {
    CleverIngredientsList,
    IngredientsTableForRecipe,
    ProductsProportionTable,
    CalcAmountTable
  },
  props: {
    set_is_products_empty: {
      Function
    }
  },
  data() {
    return {
      amountTable: [],
      stepNumber: 1,

      recipeName: '',

      candy_type: 3,

      info: {
          "candy_type": 3, // 0 - трюфель, 1 - корпусная конфета,

          "trufel": false,
          'candy': false,
          'custom': false,
          'ganashe': false,

          "sweeteners_portion": -1,
          "sweeteners_ratio": [],

          "chocolates_ratio": [],
          "wet_ingredients_ratio": [],
          "soft_oil_ratio": [],
          "solid_oil_ratio": [],
          "alcohol_ratio": [],

          "dry_ingredients_portion": -1,
          "dry_ingredients_ratio": [],

          "components":
          {
              "cacao": 0, "sugar": 0, "soft_fats": 0, "water": 0, "alcohol": 0, "d_s": 0
          }
      },
      components_name: COMPONENT_NAME,
      save_status: SAVE_STATUS,

      recipeSaveStatus: SAVE_STATUS.CREATED_NOT_SAVED,

      enableCalculating: false,
      isResults: false,

      restrictionEdit: true,
      productProportion: [],
      finalRecipeProportion: {},
      recipeNameErrMsg: '',
      products: [],

      text: all_text,
      rules: all_rules,
      custom_rules: {
        recipeNameUnique: value => {
          let recipes = this.$store.getters.RECIPES != null ? this.$store.getters.RECIPES : [];
          let temp = value ? value.trim() : value;
          return (!!recipes.every(info => info.name != temp) || 'Такое имя уже существует');
        },
      },
    }
  },
  watch: {
    stepNumber() {
      let is_products_empty = this.stepNumber == 0
      this.set_is_products_empty(is_products_empty)
    },
    recipeName() {
      if (this.recipeSaveStatus == SAVE_STATUS.SAVED) {
        this.recipeSaveStatus = SAVE_STATUS.EDITED_NOT_SAVED
      }
    },
  },
  methods: {
    buildRecipe() {
      if (this.recipeSaveStatus == SAVE_STATUS.SAVED)
        this.recipeSaveStatus = SAVE_STATUS.EDITED_NOT_SAVED
      this.products.splice(0, this.products.length)
      Object.entries(this.info).forEach(([name, info]) => {
          if (name.includes('ratio') && Array.isArray(info) && info.length == 1) {
              info[0]['ratio'] = 100
          }
      })

      this.info.trufel = false
      this.info.case_candy = false
      this.info.custom = false
      this.info.ganashe = false

      if (this.candy_type == 0) {
        this.info.trufel = true
      } else if (this.candy_type == 1) {
        this.info.case_candy = true
      } else if (this.candy_type == 2) {
        this.info.ganashe = true
      } else {
        this.info.custom = true
      }

      this.$store.dispatch('CALCULATE', this.info).then((res) => {
          if (res.status != 200) {
              this.$store.commit("RAISE_ALERT",
                                  {'message': this.text.common_error_message,
                                    'status': ALERT_TYPE.ERROR})
          }
          Object.entries(res.data).forEach(([name, item]) => {
              // calc on 1rg
              item['amount'] = item['ratio']
              this.products.push(item)
          })
      }).catch((err) => {
        this.$store.commit("RAISE_ALERT", 
                            {'message': this.text.common_error_message,
                                'status': ALERT_TYPE.ERROR})
      });
    },
    saveFinalRecipeProportion(finalProportion) {
        Object.keys(this.components_name).forEach((component) => {
            this.finalRecipeProportion[component] = finalProportion[component]
        });
    },
    saveRecipe() {
      let results = saveReceptToDB(this.recipeSaveStatus == SAVE_STATUS.CREATED_NOT_SAVED,
                                   this.recipeName, this.products,
                                   this.finalRecipeProportion, this.recipeID)
      results.then((res) => {
        if (res.otherError) {
          this.$store.commit("RAISE_ALERT",
            {'message': res.otherError,
            'status': ALERT_TYPE.ERROR})
          let el = document.getElementById('ingredientsTableSimpleCalc');
          if (el) {
            el.scrollIntoView({behavior: "smooth"});
          }
        } else if (res.recipeNameErr) {
          this.recipeNameErrMsg = res.recipeNameErr
          let el = document.getElementById('recipeNameCleverCalc');
          if (el) {
            el.scrollIntoView({behavior: "smooth"});
          }
        } else if (res.status == 200 || res.status == 201) {
          this.recipeSaveStatus = SAVE_STATUS.SAVED
          this.recipeID = res.data.id
          this.$store.commit("RAISE_ALERT",
                  {'message': this.text.recipe.recipe_saved,
                    'status': ALERT_TYPE.SUCCESS})
        } else {
          this.$store.commit("RAISE_ALERT",
          {'message': res.otherError,
          'status': ALERT_TYPE.ERROR})
        }
      }).catch((err) => {
        this.$store.commit("RAISE_ALERT",
          {'message': err.response.data['message'],
           'status': ALERT_TYPE.ERROR})
      })
    },
    resetRecipe() {
      Object.assign(this.$data, this.$options.data())
      this.set_is_products_empty(true)
      let el = document.getElementById('recipeNameCleverCalc');
      if (el) {
        el.scrollIntoView({behavior: "smooth"});
      }
    }
  }
}
</script>

<style>
.before_start_notice {
  display: grid;
  grid-template-columns: 10% auto 10%;
  font-size: 12px;
  color: var(--v-green-base);
  margin-top: 30px;
  margin-bottom: 50px;
  align-items: center;
}

.clever-calc-next-btn {
  font-size: 14px !important;
  margin-top: 30px;
  margin-bottom: 10px;
  margin-left: 12px;
  padding: 5px 18px;
}

.short-green-line {
  width: 100%;
  height: 1px; /* or whatever. */
  background-color: var(--v-green-base);
}

.v-input--checkbox .v-label {
  font-size: 14px !important;
}

.step-header-container {
  display: flex;
  align-items: center;
}

.step-header-text {
  color: var(--v-green-base);
  white-space: nowrap;
  margin-right: 10px;
}

.candy-type-btn {
  font-size: 12px !important;
  height: 35px !important;
  width: 25%;
}

.toggle-buttons-clevel-calc {
    width: 100%;
    justify-content: center;
}

@media (max-width: 950px) {
  .toggle-buttons-clevel-calc {
      width: 100%;
      justify-content: center;
      display: grid !important;
      grid-template-columns: 50% 50%;
  }

  .candy-type-btn {
    width: 100%;
    border-width: 1px !important;
    border-radius: 0px !important;
  }
}
</style>
