<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width=500px>

      <v-card color="green">
        <v-card-text class="change-calc-text">
          <span>{{ text.calculator.toggle_calcs }}</span>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
            <button
              class="common-button change-calc-button"
              @click="toggleAndClose()">
              {{ text.calculator.go_ahead }}
            </button>
            <button
              class="common-button change-calc-button"
              @click="closeDialog()">
              {{ text.cancel }}
            </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import all_text from './ru-text'
export default {
  props: {
    dialog: {
      type: Boolean
    },
    closeDialog: {
      type: Function,
    },
    toggleFunc: {
      type: Function
    }
  },
  name: 'ChangeCalculatorDialog',
  data() {
    return {
      text: all_text
    }
  },
  methods: {
    toggleAndClose() {
      this.toggleFunc()
      this.closeDialog()
    },
  }
}
</script>
<style>
@import './common.scss';

.change-calc-text {
  padding-top: 20px !important;
  font-size: 17px !important;
  text-align: center;
  color: var(--v-main_background-base) !important;
}

.change-calc-button {
  margin-left: 10px;
  padding: 5px 10px 5px 10px;
}

.err-msg {
  padding-left: 15px;
  margin-top: -10px;
  font-size: 12px;
  color: #ff5252;
}
</style>
