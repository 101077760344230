<template>
  <v-container class="amount-border">
    <div class="amount-button-container" v-if="calc_amount_dialog == false">
      <div class="amount-star">
        <img class="amount-star-icon" src="./icons/star_big.svg" /> 
      </div>
      <button
        :class="products.length == 0 || disabled_by_parent || $store.getters.LOADING ? 
                ['common-disable-text-color common-button calc-amount-button'] : 
                ['common-button calc-amount-button']"
        @click="() => { calc_amount_dialog = true }"
        :disabled="products.length == 0 || disabled_by_parent || $store.getters.LOADING">
        {{ text.recipe.calculate_on_amount }}
      </button>
    </div>
    <div class="calc-amount-title" v-else>
      <div style="justify-self: end;">{{ text.recipe.calculate_on_amount }}</div>
    </div>
    <div v-if="calc_amount_dialog == true">
      <div class="measure-type-container">
        <v-btn-toggle
          class="toggle-buttons"
          v-model="step"
          color="primary"
          mandatory>
          <v-btn :value="0" :class="step == 0 ? 'toggle-one-button active-toggle-green measure-type-btn' :
                                                'toggle-one-button not-active-toggle-green measure-type-btn'"
            style="border-style: solid; border-color: var(--v-green-base) !important; border-width: 1px 0px 1px 1px;">
              {{ $vuetify.breakpoint.width > tablet_border ? text.recipe.by_final_weight : text.recipe.by_final_weight_short }}
          </v-btn>
          <v-btn :value="1" :class="step == 1 ? 'toggle-one-button active-toggle-green measure-type-btn' :
                                                'toggle-one-button not-active-toggle-green measure-type-btn'"
            style="border-style: solid; border-color: var(--v-green-base) !important; border-width: 1px 1px 1px 0px;">
            {{ $vuetify.breakpoint.width > tablet_border ? text.recipe.by_portion_amount : text.recipe.by_portion_amount_short }}
          </v-btn>
      </v-btn-toggle>
      </div>
      <v-window v-model="step">
        <v-window-item :value="0">
          <v-form ref="amount_by_weight" v-model="valid_by_weight" validate-on="submit input">
            <v-card
              color="main_background"
              elevation="0"
              class="clac-amount-card-custom-info"
            >
              <v-card-text style="height: 170px; display: flex; align-items: center;">
                <v-text-field
                  :rules="[rules.required, rules.double]"
                  v-model="needCandy.needCandyWeight"
                  :label="text.recipe.final_weight">
                </v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <button
                  :disabled="!valid_by_weight"
                  :class="!valid_by_weight ?
                          ['common-disable-text-color', 'common-button'] :
                          ['common-button']"
                  style="padding: 2px 10px;"
                  small @click="riseCalculation()" >
                  {{ text.calculate }}
                </button>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-window-item>
          <v-window-item :value="1">
          <v-form ref="amount_by_portion" v-model="valid_by_portion" validate-on="submit input">
            <v-card 
              color="main_background"
              elevation="0"
              class="clac-amount-card-custom-info">
              <v-card-text style="height: 170px;">
                <v-text-field
                  :rules="[rules.required, rules.double]"
                  v-model="needCandy.needCandyAmount"
                  :label="text.recipe.final_portion_amount">
                </v-text-field>
                <v-text-field
                  :rules="[rules.required, rules.double]"
                  v-model="needCandy.oneCandyWeight"
                  :label="text.recipe.one_portion_weight">
                </v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <button
                    :disabled="!valid_by_portion"
                    :class="!valid_by_portion ?
                          ['common-disable-text-color', 'common-button'] :
                          ['common-button']"
                    style="padding: 2px 10px;"
                    @click="riseCalculation()" >
                    {{ text.calculate }}
                </button>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-window-item>
      </v-window>
    </div>
    <v-divider v-if="knownFinalCandyInfo()" :thickness="4" color="green"></v-divider>
    <div style="margin: 10px; margin-top: 20px;"
      v-if="knownFinalCandyInfo()" >
      <v-row v-if="amountInfo.needCandyAmount > 0">
          <v-col sm=4 xs=6 class="text-subtitle-1">
              {{ text.recipe.portion_amount }}
          </v-col>
          <v-col sm=2 xs=4 class="text-subtitle-1">
              {{ amountInfo.needCandyAmount }}
          </v-col>
      </v-row>
      <v-row style="margin-bottom: 10px;">
          <v-col sm=4 xs=6 class="text-subtitle-1">
              {{ text.recipe.portion_weight }}
          </v-col>
          <v-col sm=2 xs=4 class="text-subtitle-1">
              {{ amountInfo.needCandyWeight }}
          </v-col>
      </v-row>
      <div id="target-table">
        <table color="main_background" class="calc-amount-table">
          <tr>
            <th class="calc-amount-table-pr-name">{{ text.recipe.product }}</th>
            <th>{{ text.recipe.amount_gramm_in_candy }}</th>
          </tr>
          <tr v-for="item in amountTable" :key="item.name + 'tr'">
            <td class="calc-amount-table-pr-name">{{ item.name }}</td>
            <td>{{ item.customWeight }}</td>
          </tr>
        </table>
      </div>
      <div style="display: flex; justify-content: end">
        <button
            class="common-button"
            style="padding: 3px 5px 3px 5px; margin-top: 20px; margin-bottom: 20px;"
            @click="saveReceptAsPDF()"
            :disabled="!knownFinalCandyInfo() || $store.getters.LOADING">
            {{ text.recipe.save_as_pdf }}
        </button>
      </div>
    </div>
    </v-container>
</template>
<script>
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import all_text from './ru-text'
import all_rules from './rules'
import { MOBILE_BORDER, TABLET_BORDER } from './define.js'

import '../assets/Roboto-Regular-normal.js'
import '../assets/Roboto-Bold-normal.js'

 export default {
    props: {
      products: { type: Array },
      recipeName: { type: String },
      amountTable: { type: Array },
      disabled_by_parent: { type: Boolean }
    },
    name: 'CalcAmountTable',
    data() {
      return {
        valid_by_weight: false, 
        valid_by_portion: false,
        step: 0,

        calc_amount_dialog: false,
        amountInfo: {"needCandyWeight": 0, 'needCandyAmount': 0, 'oneCandyWeight': 0},

        needCandy: {
          needCandyAmount: undefined,
          needCandyWeight: undefined,
          oneCandyWeight: undefined
        },

        text: all_text,
        rules: all_rules,
        tablet_border: TABLET_BORDER
      }
    },
    watch: {
      products() {
        if (!this.disabled_by_parent)
          this.riseCalculation();
      },
      disabled_by_parent(newVal) {
        if (newVal == true) {
          this.amountInfo.needCandyWeight = 0
          this.amountInfo.needCandyAmount = 0 
          this.amountInfo.oneCandyWeight = 0
          this.calc_amount_dialog = false;
          this.needCandy.needCandyWeight = undefined
          this.needCandy.needCandyAmount = undefined
          this.needCandy.oneCandyWeight = undefined
        }
      },
      step(newVal) {
        // 0 - by weight, 1 - by propotion
        if (newVal == 0) {
          if (this.amountInfo.needCandyAmount == 0 || this.amountInfo.oneCandyWeight == 0) {
            this.needCandy.needCandyAmount = undefined
            this.needCandy.oneCandyWeight = undefined
          } else {
            this.needCandy.needCandyAmount = this.amountInfo.needCandyAmount
            this.needCandy.oneCandyWeight = this.amountInfo.oneCandyWeight
          }
        } else {
          if (this.amountInfo.needCandyWeight == 0) {
            this.needCandy.needCandyWeight = undefined
          } else {
            this.needCandy.needCandyWeight = this.amountInfo.needCandyWeight
          }
        }
      }
    },
    methods: {
      riseCalculation() {
        if (this.step == 0) {
          this.amountInfo.needCandyWeight = this.needCandy.needCandyWeight
          this.amountInfo.needCandyAmount = 0 
          this.amountInfo.oneCandyWeight = 0
          this.needCandy.needCandyAmount = undefined
          this.needCandy.oneCandyWeight = undefined
        } else {
          this.amountInfo.needCandyWeight = 0
          this.needCandy.needCandyWeight = undefined
          this.amountInfo.needCandyAmount = this.needCandy.needCandyAmount
          this.amountInfo.oneCandyWeight = this.needCandy.oneCandyWeight
        }
        this.updateAmountTable();
      },
      saveReceptAsPDF() {
        let pdf = new jsPDF('p', 'pt', 'a4');

        const defaultFontSize = 10    // normal text
        const nextLineStep = 16       // normal text next line offset
        const headerLineStep = 20     // header next line offset
        const X = 40
        let lastY = 30                // Y coordinate initial value
        const recipeName = this.recipeName || this.text.recipe.new_recipe

        // print recipe name as header
        pdf.setFont('Roboto-Bold', 'normal')
        pdf.setFontSize(18)

        const splitTitle = pdf.splitTextToSize(recipeName, 500)
        pdf.text(splitTitle, X, lastY)
        lastY += splitTitle.length * headerLineStep   // step down to several lines depending on title array length

        // normal text data
        pdf.setFont('Roboto-Regular', 'normal')
        pdf.setFontSize(defaultFontSize);
        if (+this.amountInfo.needCandyAmount > 0) {
            pdf.text(`${this.text.recipe.portion_amount}: ${this.amountInfo.needCandyAmount}`, X, lastY)
            lastY += nextLineStep
        }

        pdf.text(`${this.text.recipe.portion_weight}: ${this.amountInfo.needCandyWeight}`, X, lastY)
        lastY += nextLineStep

        // таблица "расчет на количество", что бы это ни значило
        autoTable(pdf, { html: '#target-table table', styles: { font: 'Roboto-Regular', }, startY: lastY })

        pdf.save(`${recipeName}.pdf`)
      },
      knownFinalCandyInfo() {
        return (this.amountInfo.needCandyAmount > 0 || this.amountInfo.needCandyWeight > 0)
      },
      updateAmountTable() {
        let fullCurentWeight = 0
        if (this.products.at(-1)) {
          fullCurentWeight = parseFloat(this.products.at(-1).amount)
        }
        let needWeight = parseFloat(this.amountInfo.needCandyWeight)
        let oneWeight = parseFloat(this.amountInfo.oneCandyWeight)
        let needAmount = parseFloat(this.amountInfo.needCandyAmount)

          if (needAmount != 0) {
            needWeight = needAmount * oneWeight
          }

          if (needWeight == 0 && fullCurentWeight == 0) {
            this.amountInfo = []
          }

          this.amountTable.splice(0, this.amountTable.length)

          this.products.forEach(product => {
              let newItem = {}
              newItem['name'] = product['name']
              let ratio =  product['amount'] * 100 / fullCurentWeight
              newItem['customWeight'] = (needWeight * ratio / 100).toFixed(2);
              this.amountTable.push(newItem)
          });
          this.amountInfo.needCandyWeight = needWeight.toFixed(2);
      }
    }
  }
</script>

<style>
@import './common.scss';

.amount-star-icon {
  align-self: center;
  width: 30px;
  height: 30px;
}

.amount-star {
  justify-content: center;
  display: flex;
  width: 40px;
  border-radius: 6px 20px 20px 6px;
  background-color: var(--v-green-base);
  border-color: var(--v-green-base);
  border-style: solid;
  justify-self: start;
}

.calc-amount-button {
  margin: 5px 10px 5px 0px;
  height: 35px;
  font-size: 14px;
  justify-self: end;
  width: 85%;
}

.amount-border {
  height: fit-content;
  padding: 0px !important;
  border-style: solid solid solid solid;
  border-color: var(--v-green-base);
  border-radius: 10px 0px 0px 10px;
}

.amount-button-container {
  display: grid;
  grid-template-columns: auto auto;
}

.calc-amount-title {
  background-color: var(--v-green-base);
  border-color: var(--v-green-base);
  font-size: 16px !important;
  color: var(--v-main_background-base);
  border-radius: 8px 0px 0px 0px;
  height: 46px;
  display: grid;
  align-items: center;
  padding-right: 10px;
}

.measure-type-btn {
  font-size: 12px !important;
  height: 35px !important;
}

.measure-type-container {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.calc-amount-table {
  border: 1px solid;
  border-color: var(--v-green-base) !important;
  text-align: center;
}

.calc-amount-table td {
  border-bottom: 1px solid var(--v-green-base) !important;
}

.calc-amount-table-pr-name {
  text-align: start !important;
  padding-left: 10px
}

.clac-amount-card-custom-info {
  margin-left: 5px;
  margin-right: 5px;
  height: 220px;
}

@media (max-width: 950px) {
  .amount-border {
    border-radius: 10px 10px 10px 10px;
  }

  .calc-amount-title {
    border-radius: 8px 8px 0px 0px;
  }

}

</style>
